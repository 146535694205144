import React, { Component } from 'react';
import 'index.css';
import 'components/interface/Bouton.scss';

class Bouton extends Component {
    constructor(props){
        super(props);
        this.state={
            idx:0,
        }
    }
    render() {
        let classes="itf-bouton";
        if (this.props.disabled) classes+=" disabled";
        if (this.props.className) classes+=" "+this.props.className;
        return <div className={classes} onClick={this.props.onClick}>
            <div className="bouton-shadow">
            </div>
            <div className="bouton-inner">
                {this.props.text}
            </div>
        </div>
    }
}
export default Bouton;
