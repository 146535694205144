import React, { Component } from 'react';
import 'index.css';
import './Controls.css';
import './FullScreenBtn.scss';

class FullScreenBtn extends Component {
    constructor(props) {
        super(props);
        this.state={
            fullscreen:false
        }
        this.handleFullscreenChange = this.handleFullscreenChange.bind(this);
    }
    handleFullscreenChange(){
        if ( document.fullscreen ) {
            this.setState({fullscreen:true});
        } else {
            this.setState({fullscreen:false});
        }
    }
    componentDidMount() {
        document.addEventListener('fullscreenchange',this.handleFullscreenChange);
    }
    componentWillUnmount() {
        document.removeEventListener('fullscreenchange',this.handleFullscreenChange);
    }
    toggleFullScreen(){
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            document.body.requestFullscreen();
        }
    }
    render() {
        let btnClass="controls-fullscreen-btn controls-btn";
        if (this.state.fullscreen) {
            btnClass+=" fullscreen";
        }
        return (
            <span className={btnClass} onClick={this.toggleFullScreen}>
            <span className='controls-fullscreen-on'>
                <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 16.235 16.039"
                height="16.039"
                width="16.235"
                id="full-screen">
                <defs
                 id="defs1543" />
                <path
                 id="Tracé_67"
                 data-name="Tracé 67"
                 d="m 6.6599724,1.2449822 0.069,5.023 a 0.456,0.456 0 0 1 -0.46,0.461 l -5.023,-0.073 a 0.455,0.455 0 0 1 -0.31700001,-0.777 l 1.40000001,-1.4 -2.32500001,-2.321 2.15800001,-2.15799999286 2.324,2.32399999286 1.4,-1.39999999 a 0.457,0.457 0 0 1 0.774,0.32099999 z"
                 />
                <path
                 id="Tracé_68"
                 data-name="Tracé 68"
                 d="m 11.755028,2.3569723 -1.4,-1.40000001 A 0.455,0.455 0 0 0 9.5780277,1.2719723 l -0.073,5.023 a 0.456,0.456 0 0 0 0.461,0.461 l 5.0230003,-0.073 a 0.455,0.455 0 0 0 0.315,-0.777 l -1.394,-1.391 2.327,-2.321 -2.158,-2.15800001 z"
                 />
                <path
                 id="Tracé_69"
                 data-name="Tracé 69"
                 d="m 15.298028,10.165028 a 0.455,0.455 0 0 0 -0.315,-0.7770001 l -5.0230004,-0.073 a 0.456,0.456 0 0 0 -0.461,0.461 l 0.073,5.0230001 a 0.455,0.455 0 0 0 0.7770004,0.315 l 1.4,-1.4 2.324,2.324 2.158,-2.158 -2.324,-2.324 z"
                />
                <path
                 id="Tracé_70"
                 data-name="Tracé 70"
                 d="m 6.2620002,9.2790175 -5.0230003,0.073 A 0.455,0.455 0 0 0 0.92399986,10.129018 l 1.40000004,1.394 -2.3240000387227,2.324 2.1580000387227,2.158 2.324,-2.324 1.4,1.4 a 0.455,0.455 0 0 0 0.7770003,-0.315 l 0.068,-5.0260005 a 0.461,0.461 0 0 0 -0.465,-0.461 z"
                 />
                </svg>
                </span>
                <span className='controls-fullscreen-off'>
                <svg
                   xmlns="http://www.w3.org/2000/svg"
                   version="1.1"
                   viewBox="0 0 16.235 16.039"
                   height="16.039"
                   width="16.235"
                   id="full-screen">
                  <defs
                     id="defs10" />
                  <g
                     transform="translate(0 0)"
                     data-name="Groupe 15"
                     id="Groupe_15">
                    <path
                        transform="translate(-0.096 -2.95)"
                       d="M.169,8.434.1,3.411A.456.456,0,0,1,.56,2.95l5.023.073A.455.455,0,0,1,5.9,3.8L4.5,5.2,6.825,7.521,4.667,9.679,2.343,7.355l-1.4,1.4A.457.457,0,0,1,.169,8.434Z"
                       data-name="Tracé 67"
                       id="Tracé_67" />
                    <path
                       transform="translate(-276.795 -3.917)"
                       d="M290.782,8.352l1.4,1.4a.455.455,0,0,0,.777-.315l.073-5.023a.456.456,0,0,0-.461-.461l-5.023.073a.455.455,0,0,0-.315.777l1.394,1.391L286.3,8.515l2.158,2.158Z"
                       data-name="Tracé 68"
                       id="Tracé_68" />
                    <path
                     transform="translate(-276.698 -274.139)"
                       d="M287.13,289.327a.455.455,0,0,0,.315.777l5.023.073a.456.456,0,0,0,.461-.461l-.073-5.023a.455.455,0,0,0-.777-.315l-1.4,1.4-2.324-2.324-2.158,2.158,2.324,2.324Z"
                       data-name="Tracé 69"
                       id="Tracé_69" />
                    <path
                       transform="translate(0 -273.172)"
                       d="M.465,289.177l5.023-.073a.455.455,0,0,0,.315-.777l-1.4-1.394,2.324-2.324-2.158-2.158-2.324,2.324-1.4-1.4a.455.455,0,0,0-.777.315L0,288.716A.461.461,0,0,0,.465,289.177Z"
                       data-name="Tracé 70"
                       id="Tracé_70" />
                  </g>
                </svg>
                </span>
            </span>
        );
    }
}
export default FullScreenBtn;
