import React, { Component } from 'react';
import 'index.css';
import 'components/Playing.scss';
import Play from 'components/interface/Play';
const ThemeContext = React.createContext();

class Playing extends Component {
    constructor(props){
        super(props);
        this.handleGoToLive=this.handleGoToLive.bind(this);
    }
    handleGoToLive(id){
        this.props.onGoToLive();
        this.props.goTo('/live/'+id);
    }
    render() {
        let classes="playing-span";
        if (this.props.paused) classes+=" paused";
        let opacity=this.props.current===this.props.idx ? 1 : 0.5;
        return <span className={classes} onClick={this.props.onClick}>
            <Play style={{width:24,height:24,margin:0,opacity:opacity}} shadowOnly playing={this.props.current===this.props.idx && !this.props.paused}/> {this.props.texte} {this.context}
        </span>;
    }
}
Playing.contextType = ThemeContext;
export default Playing;
