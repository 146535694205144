import React from 'react';
import * as Subtitle from 'subtitle';
import NaisserSub from 'assets/subtitles/01_FAMILLE_MASTER_1';
import ApreneSub from 'assets/subtitles/02_FORMATION_MASTER';
import TornarSub from 'assets/subtitles/03_RETOUR_MASTER';
import PosarSub from 'assets/subtitles/04_POSITIONNEMENT_MASTER';
import AlandarSub from 'assets/subtitles/05_OUVERTURE_MASTER';
import Playing from 'components/Playing';
import Conf from 'utils/Conf';
export default {
    edito:[
        {
            title:'édito',
            text:<>
            <p>Dans le cadre de sa mission de valorisation du patrimoine oral, le
            Centre Occitan des Musiques et Danses Traditionnelles Toulouse Occitanie
            (COMDT) a proposé, en intégrant le projet du CIRDÒC Cultura Viva -
            Fabrique numérique collective des données et ressources du patrimoine
            culturel immatériel régional, la réalisation d'un web documentaire sur
            le thème de la transmission des musiques traditionnelles.</p>
            <p>Celles-ci, ancrées dans les territoires depuis des siècles,
            puis, concomitamment à leur déclin, inscrites dans une renaissance
            constituée de plusieurs étapes, ont continué à se transmettre, avec,
            comme permanences, l'oralité, le lien à la danse, à une culture et
            bien souvent à une langue, combinant connaissance des sources et
            invention des formes, loin de toute « folklorisation ».</p>
            <p>Les personnes collectées sont issues d'esthétiques et de territoires
            différents, montrant ainsi que la « tradition » (en latin traditio,
            « acte de transmettre ») s'ancre et chemine dans et à travers
            divers contextes, familiaux, territoriaux, culturels, imaginaires,
            et ne s'appréhende qu'en perpétuel mouvement.</p>
            <p>L'idée initiale était d'interroger les enfants des acteurs de la
            période dite revivaliste des musiques traditionnelles du domaine
            occitan, la famille étant généralement la première matrice de la
            transmission inter-générationnelle.</p>
            <p>Mais, poussant la réflexion, nous avons étendu le champ d'investigation
            à des personnalités pour qui la transmission familiale d'un patrimoine
            artistique et musical n'était pas évidente. Au-delà du seul cadre
            familial, il est un fait que les musiques traditionnelles se transmettent
            et nous avons choisi de mettre en évidence une génération qui est
            aujourd'hui moteur, à différents titres, de cette transmission.</p>
            </>
        },
        {
            title:'',
            text:<>
            <p>À travers les entretiens qui composent les cinq chapitres de ce
            web documentaire, dans lequel figurent également de nombreux liens
            vers des archives sonores et audiovisuelles ou des productions
            d'aujourd'hui, nous sommes passés de l'imprégnation à l'émancipation
            en abordant les différentes phases de la vie de musicien.</p>
            <p>Enfants de collecteurs, d'instrumentistes, de chanteurs, de danseurs,
            de formateurs, de mélomanes... ils ont été transportés dès leur plus
            jeune âge dans les lieux, contextes et récits de pratique familiale,
            et chacun a grandi au gré de rencontres qui ont bâti sa sensibilité.</p>
            <p>Entrer en musique, c'était pour beaucoup apprendre un instrument,
            mais aussi la danse, le chant, la langue, l'expression scénique.
            C'était aussi se confronter aux autres pratiques des groupes de leur
            âge, ressentir un décalage culturel et vouloir se démarquer de leurs
            aînés, parfois jusqu'à la rupture avec leur parcours musical. Il a
            fallu ensuite dépasser l'antagonisme, reconsidérer la transmission
            comme un héritage, comme une matière à jouer, à penser, à créer,
            remonter le fil de leur pratique, en rechercher les sources ;
            enfin s'émanciper et choisir leur(s) trajectoire(s).</p>
            <p>Les entretiens ont été filmés d'avril à octobre 2019 et ont été
            préparés et menés par <strong>Guilhem Boucher</strong>, musicien, enseignant, chercheur,
            acteur associatif au sein de La Granja de Soulomès (Lot) et de
            l'Institut d'Estudis Occitans d'Òlt, dont les compétences ont permis
            la réalisation de ce projet, qu’il en soit vivement remercié.</p>
            </>
        }
    ],
    credits:<>
    <p>Conception et documentation</p>
    <p className="generique-nom">COMDT Toulouse Occitanie<br />
    <img className='logo-comdt' alt='logo COMDT' src={Conf.base+"/logos/comdt.jpg"}/>
    </p>
    <p>Ecriture et entretiens</p>
    <p className="generique-nom">Guilhem BOUCHER</p>
    <p>Réalisation</p>
    <p className="generique-nom">Amic BEDEL <small>PIGET FILMS</small></p>
    <p>Développement web</p>
    <p className="generique-nom">Fabrice LAPEYRERE <small>LE FIL</small></p>
    <p>&nbsp;</p>
    <p>Producteur délégué</p>
    <p className="generique-nom">COMDT Toulouse Occitanie</p>
    <p>Producteur exécutif</p>
    <p className="generique-nom">PIGET FILMS</p>
    <p>&nbsp;</p>
    <p>Nous remercions pour leurs témoignages</p>
    <p className="generique-nom">Matèu & Tomàs BAUDOIN<br />
    Hervé CAPEL<br />
    Caroline DUFAU<br />
    Clément GAUTHIER<br />
    Aelis LODDO<br />
    Anaïs VAILLANT<br />
    Mickaël VIDAL</p>
    <p>&nbsp;</p>
    <p>Nous remercions également</p>
    <p className="generique-nom">Alexandra Astorg<br />
    Jacques Baudoin<br />
    Romain Baudoin<br />
    CORDAE&nbsp;/ La Talvera <br /><small>(Centre Occitan de Recherche et de Documentation Ethnographique)</small><br />
    le CRMTL <br /> <small>(Centre Régional des Musiques Traditionnelles en Limousin)</small><br />
    La Filmotec-Nice <small>(association Autour de)</small><br />
    Hedningarna<br />
    l'IOA <small>(Institut Occitan de l'Aveyron)</small><br />
    la Phonothèque de la Maison <br />méditerranéenne des sciences de l'homme<br />
    Maurice Moncozet<br />
    Pagans<br />
    Patrick Paquier<br />
    Tròba Vox<br />
    Patrick Vaillant<br />
    Xavier Vidal</p>
    <p>&nbsp;</p>
    <p>Ce web documentaire a été réalisé dans le cadre du projet</p>
    <p className="generique-nom">Cultura Viva<br /><small>Fabrique numérique collective
    des données et ressources <br />du patrimoine culturel immatériel régional</small></p>
    <p>En partenariat avec</p>
    <p className="generique-nom">le CIRDÒC<br /><small>Institut occitan de cultura</small><br />
    <img className='logo-cirdoc' alt='logo CIRDOC' src={Conf.base+"/logos/cirdoc.png"}/>
    </p>
    <p>&nbsp;</p>
    <p>Avec le soutien de la </p>
    <p className="generique-nom">Région Occitanie&nbsp;/ Pyrénées-Méditerranée<br />
    <img className='logo-region' alt='logo Region Occitanie' src={Conf.base+"/logos/region_occitanie.jpg"}/>
    </p>
    </>,
    mentions:<>
    <h3>Éditeur</h3>
    <p>COMDT Toulouse Occitanie<br />
    5 rue du Pont de Tounis<br />
    31000 Toulouse<br />
    contact@comdt.org
    </p>
    <h3>Développement & Design</h3>
    <p>Le Fil<br />
    Féniès<br />
    81120 Saint Antonin de Lacalm<br />
    fabrice.lapeyrere@surlefil.org
    </p>
    <h3>Hébergeur</h3>
    <p>OVHCloud<br />
	2 rue Kellermann<br />
    59100 Roubaix
    </p>
    </>,
    mainVideos:[
        {
            id:'naisser',
            videoId:'https://vimeo.com/432842893/b05ec7de73',
            thumbnail:'/imgs/main_videos/naisser.jpg',
            title:'Nàisser',
            translation:'naître',
            desc:<>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac nisi faucibus, luctus sem ut, convallis velit. Cras iaculis feugiat tortor quis luctus. Aliquam sed ligula ante. Suspendisse potenti. Pellentesque a ante ut nisi malesuada varius. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Pellentesque cursus suscipit nunc, ullamcorper tincidunt neque lacinia sed. Ut consectetur facilisis dui, vitae fermentum odio placerat non. Aenean vehicula nibh sed orci dapibus, at placerat sem bibendum. Aenean blandit tincidunt nulla.
            </>,
            complements:[
                {
                    start:42,
                    stop:50,
                    id:'patrick_vaillant',
                },
                {
                    start:155,
                    stop:163,
                    id:'canicula',
                },
                {
                    start:352,
                    stop:360,
                    id:'la_talvera_collectage',
                },
                {
                    start:368,
                    stop:376,
                    id:'jacques_baudoin_collectage',
                },
            ],
            osd:[
                {
                    start:72,
                    stop:82,
                    id:'anais',
                    artist:'Anaïs VAILLANT',
                    lives:[],
                    links:[{label:"Le blog d'Anaïs VAILLANT",url:'https://multiplicit.blogspot.com/'}],
                },
                {
                    start:125,
                    stop:135,
                    id:'caro',
                    artist:'Caroline DUFAU',
                    lives:['cocanha'],
                    collectages:['martin','aital'],
                },
                {
                    start:227,
                    stop:237,
                    id:'clement',
                    artist:'Clément GAUTHIER',
                    lives:['jericho'],
                },
                {
                    start:328,
                    stop:338,
                    id:'herve',
                    artist:'Hervé CAPEL',
                    lives:['duo_artense'],
                    collectages:['montagnarde','peras','chabrit'],
                },
                {
                    start:340,
                    stop:350,
                    id:'aelis',
                    artist:'Aelis LODDO',
                    lives:['yenalaba','la_talvera'],
                },
                {
                    start:423,
                    stop:433,
                    id:'baudoins',
                    artist:'Matèu & Tomàs BAUDOIN',
                    lives:['artus','chrone','d_en_haut'],
                    collectages:['hilhas','molin','bienuros'],
                },
            ],
            subtitles:Subtitle.parse(NaisserSub),
        },
        {
            id:'aprene',
            videoId:'https://vimeo.com/433138965/989eb7d1fc',
            thumbnail:'/imgs/main_videos/aprene.jpg',
            title:'Aprene',
            translation:'apprendre',
            desc:<>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac nisi faucibus, luctus sem ut, convallis velit. Cras iaculis feugiat tortor quis luctus. Aliquam sed ligula ante. Suspendisse potenti. Pellentesque a ante ut nisi malesuada varius. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Pellentesque cursus suscipit nunc, ullamcorper tincidunt neque lacinia sed. Ut consectetur facilisis dui, vitae fermentum odio placerat non. Aenean vehicula nibh sed orci dapibus, at placerat sem bibendum. Aenean blandit tincidunt nulla.
            </>,
            complements:[
                {
                    start:52,
                    stop:60,
                    id:'zephirin',
                },
                {
                    start:196,
                    stop:204,
                    id:'xavier_vidal',
                },
                {
                    start:259,
                    stop:267,
                    id:'chancaires',
                },
                {
                    start:315,
                    stop:323,
                    id:'canicula',
                },
                {
                    start:358,
                    stop:363,
                    id:'flute',
                },
            ],
            osd:[
                {
                    start:218,
                    stop:228,
                    id:'mickael',
                    artist:'Mickaël VIDAL',
                    lives:['farce_dure'],
                },
                {
                    start:304,
                    stop:314,
                    id:'caro',
                    artist:'Caroline DUFAU',
                    lives:['cocanha'],
                    collectages:['martin','aital'],
                },
                {
                    start:413,
                    stop:423,
                    id:'clement',
                    artist:'Clément GAUTHIER',
                    lives:['jericho'],
                },
                {
                    start:467,
                    stop:477,
                    id:'anais',
                    artist:'Anaïs VAILLANT',
                    lives:[],
                    links:[{label:"Le blog d'Anaïs VAILLANT",url:'https://multiplicit.blogspot.com/'}],
                },
                {
                    start:525,
                    stop:535,
                    id:'baudoins',
                    artist:'Matèu & Tomàs BAUDOIN',
                    lives:['artus','chrone','d_en_haut'],
                    collectages:['hilhas','molin','bienuros'],
                },
                {
                    start:559,
                    stop:569,
                    id:'aelis',
                    artist:'Aelis LODDO',
                    lives:['yenalaba','la_talvera'],
                },
                {
                    start:593,
                    stop:603,
                    id:'herve',
                    artist:'Hervé CAPEL',
                    lives:['duo_artense'],
                    collectages:['montagnarde','peras','chabrit'],
                },
            ],
            subtitles:Subtitle.parse(ApreneSub),
            subtitlesOffset:-5,
        },
        {
            id:'tornar',
            videoId:'https://vimeo.com/433205754/3bdccd9097',
            thumbnail:'/imgs/main_videos/tornar.jpg',
            title:'Tornar',
            translation:'revenir',
            desc:<>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac nisi faucibus, luctus sem ut, convallis velit. Cras iaculis feugiat tortor quis luctus. Aliquam sed ligula ante. Suspendisse potenti. Pellentesque a ante ut nisi malesuada varius. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Pellentesque cursus suscipit nunc, ullamcorper tincidunt neque lacinia sed. Ut consectetur facilisis dui, vitae fermentum odio placerat non. Aenean vehicula nibh sed orci dapibus, at placerat sem bibendum. Aenean blandit tincidunt nulla.
            </>,
            complements:[
                {
                    start:133,
                    stop:141,
                    id:'carlotti',
                },
                {
                    start:228,
                    stop:236,
                    id:'jean_bona',
                },
                {
                    start:263,
                    stop:271,
                    id:'melhau',
                },
                {
                    start:330,
                    stop:338,
                    id:'carnaval',
                },
                {
                    start:479,
                    stop:487,
                    id:'hedningarna',
                },
                {
                    start:538,
                    stop:546,
                    id:'romain_baudoin',
                },
            ],
            osd:[
                {
                    start:95,
                    stop:105,
                    id:'aelis',
                    artist:'Aelis LODDO',
                    lives:['yenalaba','la_talvera'],
                },
                {
                    start:237,
                    stop:247,
                    id:'herve',
                    artist:'Hervé CAPEL',
                    lives:['duo_artense'],
                    collectages:['montagnarde','peras','chabrit'],
                },
                {
                    start:271,
                    stop:281,
                    id:'clement',
                    artist:'Clément GAUTHIER',
                    lives:['jericho'],
                },
                {
                    start:379,
                    stop:389,
                    id:'anais',
                    artist:'Anaïs VAILLANT',
                    lives:[],
                    links:[{label:"Le blog d'Anaïs VAILLANT",url:'https://multiplicit.blogspot.com/'}],
                },
                {
                    start:460,
                    stop:470,
                    id:'caro',
                    artist:'Caroline DUFAU',
                    lives:['cocanha'],
                    collectages:['martin','aital'],
                },
                {
                    start:527,
                    stop:537,
                    id:'mickael',
                    artist:'Mickaël VIDAL',
                    lives:['farce_dure'],
                },
                {
                    start:565,
                    stop:575,
                    id:'baudoins',
                    artist:'Matèu & Tomàs BAUDOIN',
                    lives:['artus','chrone','d_en_haut'],
                    collectages:['hilhas','molin','bienuros'],
                },
            ],
            subtitles:Subtitle.parse(TornarSub),
        },
        {
            id:'posar',
            videoId:'https://vimeo.com/433163608/ac6538ba6a',
            thumbnail:'/imgs/main_videos/posar.jpg',
            title:'Posar',
            translation:'puiser',
            desc:<>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac nisi faucibus, luctus sem ut, convallis velit. Cras iaculis feugiat tortor quis luctus. Aliquam sed ligula ante. Suspendisse potenti. Pellentesque a ante ut nisi malesuada varius. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Pellentesque cursus suscipit nunc, ullamcorper tincidunt neque lacinia sed. Ut consectetur facilisis dui, vitae fermentum odio placerat non. Aenean vehicula nibh sed orci dapibus, at placerat sem bibendum. Aenean blandit tincidunt nulla.
            </>,
            complements:[
                {
                    start:30,
                    stop:38,
                    id:'leon_peyrat',
                },
                {
                    start:85,
                    stop:93,
                    id:'zephirin',
                },
                {
                    start:148,
                    stop:156,
                    id:'troubadour',
                },
            ],
            osd:[
                {
                    start:111,
                    stop:121,
                    id:'anais',
                    artist:'Anaïs VAILLANT',
                    lives:[],
                    links:[{label:"Le blog d'Anaïs VAILLANT",url:'https://multiplicit.blogspot.com/'}],
                },
                {
                    start:441,
                    stop:451,
                    id:'herve',
                    artist:'Hervé CAPEL',
                    lives:['duo_artense'],
                    collectages:['montagnarde','peras','chabrit'],
                },
                {
                    start:502,
                    stop:512,
                    id:'mickael',
                    artist:'Mickaël VIDAL',
                    lives:['farce_dure'],
                },
                {
                    start:589,
                    stop:599,
                    id:'caro',
                    artist:'Caroline DUFAU',
                    lives:['cocanha'],
                    collectages:['martin','aital'],
                },
                {
                    start:776,
                    stop:786,
                    id:'clement',
                    artist:'Clément GAUTHIER',
                    lives:['jericho'],
                },
                {
                    start:829,
                    stop:839,
                    id:'baudoins',
                    artist:'Matèu & Tomàs BAUDOIN',
                    lives:['artus','chrone','d_en_haut'],
                    collectages:['hilhas','molin','bienuros'],
                },
            ],
            subtitles:Subtitle.parse(PosarSub),
        },
        {
            id:'alandar',
            videoId:'https://vimeo.com/433111019/2d3a2533d9',
            thumbnail:'/imgs/main_videos/alandar.jpg',
            title:'Alandar',
            translation:'ouvrir les ailes',
            desc:<>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac nisi faucibus, luctus sem ut, convallis velit. Cras iaculis feugiat tortor quis luctus. Aliquam sed ligula ante. Suspendisse potenti. Pellentesque a ante ut nisi malesuada varius. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Pellentesque cursus suscipit nunc, ullamcorper tincidunt neque lacinia sed. Ut consectetur facilisis dui, vitae fermentum odio placerat non. Aenean vehicula nibh sed orci dapibus, at placerat sem bibendum. Aenean blandit tincidunt nulla.
            </>,
            complements:[
                {
                    start:239,
                    stop:247,
                    id:'vstgm',
                },
            ],
            osd:[
                {
                    start:78,
                    stop:88,
                    id:'herve',
                    artist:'Hervé CAPEL',
                    lives:['duo_artense'],
                    collectages:['montagnarde','peras','chabrit'],
                },
                {
                    start:106,
                    stop:116,
                    id:'aelis',
                    artist:'Aelis LODDO',
                    lives:['yenalaba','la_talvera'],
                },
                {
                    start:148,
                    stop:158,
                    id:'caro',
                    artist:'Caroline DUFAU',
                    lives:['cocanha'],
                    collectages:['martin','aital'],
                },
                {
                    start:163,
                    stop:173,
                    id:'mickael',
                    artist:'Mickaël VIDAL',
                    lives:['farce_dure'],
                },
                {
                    start:299,
                    stop:309,
                    id:'baudoins',
                    artist:'Matèu & Tomàs BAUDOIN',
                    lives:['artus','chrone','d_en_haut'],
                    collectages:['hilhas','molin','bienuros'],
                },
                {
                    start:348,
                    stop:358,
                    id:'anais',
                    artist:'Anaïs VAILLANT',
                    lives:[],
                    links:[{label:"Le blog d'Anaïs VAILLANT",url:'https://multiplicit.blogspot.com/'}],
                },
                {
                    start:423,
                    stop:433,
                    id:'clement',
                    artist:'Clément GAUTHIER',
                    lives:['jericho'],
                },
            ],
            subtitles:Subtitle.parse(AlandarSub),
        },
    ],
    lives:[
        {
            title:'farce dure',
            sort:6,
            desc:<>
            Le groupe Farce Dure est ici composé de Mickaël Vidal (chant), Marthe Tourret (violon), Charlotte Espieussas (accordéon chromatique), Jean Lacarrière (sax ténor), Tristan Charles-Alfred (sax baryton), Mathias Bayle (percussions) et Antoine Perdriolle (percussions). Il a été filmé par Piget Films au Taquin (Toulouse, 31) le 6 octobre 2019.
            </>,
            id:'farce_dure',
            thumbnail:'/imgs/lives/farce_dure.jpg',
            videoId:'https://vimeo.com/433336686/6c498c73e8',
        },
        {
            title:'cocanha',
            sort:4,
            desc:<>
            Cocanha interprète <i>Cotelon</i> à la Centrifugeuse (Pau, 64) le 7 février 2020 lors du PAGANS TOUR. Le groupe composé de Caroline Dufau, Lila Fraysse et Maud Herrera a été filmé par Piget Films.
            </>,
            id:'cocanha',
            thumbnail:'/imgs/lives/cocanha.jpg',
            videoId:'https://vimeo.com/433332591/305c79ad91',
            format:[1920,1080],
        },
        {
            title:'artús',
            sort:2,
            id:'artus',
            desc:<>
            Artús filmé par Piget Films le 7 février 2020 à la Centrifugeuse (Pau, 64) à l'occasion du PAGANS TOUR. Le groupe interprète <i>Faust</i>. Il est composé de Matèu Baudoin (chant, violon, tambourin à cordes), Tòmas Baudoin (chant, boha, tambourin à cordes, synthèse), Romain Colautti (basse, guitare baryton), Romain Baudoin (vielle à roue), Nicolas Godin (guitare, percussions, batterie) et Alexis Toussaint (batterie).
            </>,
            thumbnail:'/imgs/lives/artus.jpg',
            videoId:'https://vimeo.com/433314611/03a1dd44f9',
            format:[1920,810],
        },
        {
            title:'duo artense',
            sort:1,
            id:'duo_artense',
            desc:<>
            Le duo Artense est composé de Hervé Capel (accordéon chromatique) et Basile Brémaud (violon). Il a été filmé par Piget Films.
            </>,
            thumbnail:'/imgs/lives/artense.jpg',
            videoId:'https://vimeo.com/433307423/8e525fcb50',
        },
        {
            title:'jericho',
            sort:7,
            id:'jericho',
            desc:<>
            Le groupe Jéricho avec Clément Gauthier (chant, chabrette, tambourin à cordes), Antoine Cognet (banjo), Jacques Puech (chant, cabrette) et Yann Gourdon (vielle à roue, pieds). Filmé par Grégoire Orio en 2019.
            </>,
            thumbnail:'/imgs/lives/jericho.jpg',
            videoId:'https://vimeo.com/436394737/89dfc066e5',
            format:[1920,818],
        },
        {
            title:'yenalaba',
            sort:9,
            id:'yenalaba',
            desc:<>
            Le groupe Yenalaba avec Aelis Loddo (violon, alto, chant), Mona Hilaly (harpe à leviers, mandoline d'octave, oud, chant), Théophile Joubert (violoncelle, blaglama, oud, chant) et Massimiliano Cangelosi (tammorra, tamburello, oud, bendir), filmé au festival Les Bals d'Equinoxe (Eourres, 05) en 2019.</>,
            thumbnail:'/imgs/lives/yenalaba.jpg',
            videoId:'https://vimeo.com/436418311/1dfd245228',
        },
        {
            title:'D\'en haut',
            sort:5,
            desc:<>
            Le duo D'En Haut avec Tòmas Baudoin (chant, bols chantant, shrutti box, boha, tambourin à cordes, percussions) et Romain « Pairbon » Colautti (contrebasse, boha, tambourin à cordes, percussions) filmé à la médiathèque de Pau (64) en 2018 par Pantxo Arretz.
            </>,
            id:'d_en_haut',
            thumbnail:'/imgs/lives/d_en_haut.jpg',
            videoId:'https://vimeo.com/435126546/0b2d779fbb',
            format:[1920,1080],
        },
        {
            title:'La talvera',
            sort:8,
            desc:<>
            Le groupe La Talvera avec dans cette vidéo Aelis Loddo (chant), Céline Ricard (chant), Daniel Loddo (cavaquinho), Fabrice Rougier (clarinette), Sergio Saraniche (guitare) et Serge Cabau (percussions) interprète <i>Lo meu país</i> au festival l'Estivada (Rodez, 12) en 2015.
            </>,
            id:'la_talvera',
            thumbnail:'/imgs/lives/talvera.jpg',
            videoId:'https://vimeo.com/436421641/2c274efbb2',
            format:[1920,1080],
        },
        {
            title:'Chrone',
            sort:3,
            id:'chrone',
            desc:<>
            Extrait du projet solo <i>Chr/O/ne</i> de Matèu Baudoin (chant, violon, tambourin à cordes, podorythmie, boîte à bourdons). Le titre <i>A tot l'entorn de l'om</i> filmé par Nicolas Godin en 2019.
            </>,
            thumbnail:'/imgs/lives/chrone.jpg',
            videoId:'https://vimeo.com/435143947/568734bcd6',
            format:[1920,1080],
        },
    ],
    collectages:[
        {
            id:'hilhas',
            title:'Hilhas n\'aimis pas tant los òmis',
            sort:35,
            type:'son',
            subTitle:'ACPA',
            src:['/sons/hilhas.mp3'],
            waveform:['/sons/hilhas.png'],
            desc:(s,m)=><>
            <p>Le groupe Artús a interprété dans l'album <i>Orb</i> une version
            du chant <Playing texte={<i>Hilhas n'aimis pas tant los òmis</i>}
            idx={0} current={s.idx} paused={!s.playing} onClick={()=>m.choosePlayer(0)}/>.
            Voici la version collectée par Pèire Boissière et Michel Berdot à Ousse-Suzan
            (40) le 24 mai 1981 auprès de deux chanteurs non identifiés. Fonds ACPA.</p>
            <p><a href="https://comdt.kentika.fr/Record.htm?idlist=203&record=19113565124919317479" target="_blank" rel="noopener noreferrer">Consulter la notice documentaire de cet enregistrement</a></p>
            </>
        },
        {
            id:'molin',
            title:"A l'entorn deu molin",
            sort:0,
            type:'son',
            subTitle:'COMDT',
            src:['/sons/molin.mp3'],
            waveform:['/sons/molin.png'],
            desc:(s,m)=><>
            <p>Le groupe Ad'Arron interprète le branlo airejan <Playing texte={<i>A l'entorn deu molin</i>}
            idx={0} current={s.idx} paused={!s.playing} onClick={()=>m.choosePlayer(0)}/>.
            Voici la version collectée par Pierre Corbefin, Maurice Roux et
            Bernard Desblancs le 15 août 1973 à Laruns (64) auprès de Jean
            Passimourt (flûte  à trois trous et tambourin à cordes) François
            Trey-Casabonne (flûte à trois trous et tambourin à cordes) et Eugène
            Lou Poueyou (accordéon diatonique). Fonds COMDT.</p>
            <p><a href="https://comdt.kentika.fr/Record.htm?idlist=207&record=19137966124919551489" target="_blank" rel="noopener noreferrer">Consulter la notice documentaire de cet enregistrement</a></p>
            </>
        },
        {
            id:'bienuros',
            title:'Au reng deus bienurós',
            sort:0.1,
            type:'son',
            subTitle:'Maurice Roux',
            src:['/sons/bienuros.mp3'],
            waveform:['/sons/bienuros.png'],
            desc:(s,m)=><>
            <p>Le duo D'En Haut interprète  le morceau <Playing texte={<i>Au reng deus bienurós</i>}
            idx={0} current={s.idx} paused={!s.playing} onClick={()=>m.choosePlayer(0)}/>
            dont voici la version collectée par Maurice Roux à Castet (64)
            auprès de Jean Passimourt (flûte à trois trous et tambourin à cordes)
            et Eugène Lou Poueyou (accordéon diatonique). Fonds Maurice Roux.</p>
            <p><a href="https://comdt.kentika.fr/Record.htm?idlist=218&record=19103012124919212949" target="_blank" rel="noopener noreferrer">Consulter la notice documentaire de cet enregistrement</a></p>
            </>
        },
        {
            id:'montagnarde',
            title:'La montagnarde',
            sort:38,
            type:'son',
            subTitle:'COMDT',
            src:['/sons/montagnarde.mp3'],
            waveform:['/sons/montagnarde.png'],
            desc:(s,m)=><>
            <p>Hervé Capel a interprété dans son album solo <i>Bourrées traditionnelles
            d’Auvergne et du Limousin</i> (AEPEM) la bourrée <Playing texte={<i>La montagnarde</i>} idx={0} current={s.idx} paused={!s.playing}
            onClick={()=>m.choosePlayer(0)}/> qui est
            ici interprétée par Joseph Aigueperse (accordéon chromatique) et
            Victor Alard (cabrette) dans une publication de 1931 (Odéon). Cet
            enregistrement a été réédité par le Conservatoire occitan en 2001
            dans le disque <i>Les musiciens aveyronnais à Paris.</i></p>
            </>
        },
        {
            id:'peras',
            title:'E quand las peras son maduras',
            sort:28,
            type:'son',
            subTitle:'COMDT',
            src:['/sons/peras.mp3'],
            waveform:['/sons/peras.png'],
            desc:(s,m)=><>
            <p>Hervé Capel a interprété dans son album solo <i>Bourrées traditionnelles
            d’Auvergne et du Limousin</i> (AEPEM) la bourrée <Playing texte={<i>E quand las peras son maduras</i>} idx={0} current={s.idx} paused={!s.playing}
            onClick={()=>m.choosePlayer(0)}/> qui est ici interprétée au violon par François Maltieux.
            Enregistrement réalisé par Guy Bertrand à Lacombe (19) le 10 avril
            1979. Fonds COMDT.</p>
            <p><a href="https://comdt.kentika.fr/Record.htm?idlist=222&record=19138586124919567689" target="_blank" rel="noopener noreferrer">Consulter la notice documentaire de cet enregistrement</a></p>
            </>
        },
        {
            id:'chabrit',
            title:'La polka du Chabrit de Bagnols',
            sort:41,
            type:'son',
            subTitle:'CRMTL',
            src:['/sons/chabrit.mp3'],
            waveform:['/sons/chabrit.png'],
            desc:(s,m)=><>
            <Playing texte={<i>La polka du Chabrit de Bagnols</i>} idx={0} current={s.idx} paused={!s.playing}
            onClick={()=>m.choosePlayer(0)}/> qu’interprète le Duo Artense a pour
            source la version collectée par Olivier Durif auprès du joueur de
            violon Alfred Mouret à Saint-Donat (63) le 1er juin 1977. Fonds CRMTL.
            <p><a href="http://violonpopulairemassifcentral.crmtl.fr/#CHAPITRE_4" target="_blank" rel="noopener noreferrer">Lien vers le webdocumentaire <i>Violon populaire en Massif Central</i>, chapitre consacré à Alfred Mouret</a></p>
            </>
        },
        {
            id:'martin',
            title:'M\'an dich Martin',
            sort:36,
            type:'son',
            subTitle:<>CORDAE&nbsp;/ La Talvera</>,
            src:['/sons/martin.mp3'],
            waveform:['/sons/martin.png'],
            desc:(s,m)=><>
            <p>La bourrée <Playing texte={<i>M'an dich Martin</i>} idx={0} current={s.idx} paused={!s.playing}
            onClick={()=>m.choosePlayer(0)}/> qu'interprète Cocanha a pour source
            la version collectée par Daniel Loddo auprès de Félix Trébosc aux
            Cazes (12) le 24 juin 1994. Fonds CORDAE/La Talvera.</p>
            </>
        },
        {
            id:'aital',
            title:'Tant que farem aital',
            sort:42,
            subTitle:'Institut Occitan de l’Aveyron',
            type:'video',
            thumbnail:'/imgs/complements/aital.jpg',
            videoId:'https://vimeo.com/442008223/3dff71aba8',
            format:[600,480],
            desc:<>
            <p><i>Tant que farem aital</i> interprété par Cocanha a pour source
            l'enregistrement réalisé par Christian-Pierre et Amic Bedel à Aubin
            (12) en 2000 auprès de René Barthe.</p>
            <p><a href="https://www.occitan-aveyron.fr/fr/diffusio/source/aubin/partirem-pas-d-aici_SRC6211#wei0%5Breactway0%5D=%7CLiMediathequeV01%7CDefaut%7C21%7C1%7CMoMediathequeV01%7C-ren%C3%A9%20barthe%7C%7C%7CFO" target="_blank" rel="noopener noreferrer">Consulter la notice documentaire de cet enregistrement</a></p>
            </>
        },
    ],
    complements:[
        {
            id:'romain_baudoin',
            title:'Romain Baudoin',
            sort:1,
            type:'video',
            thumbnail:'/imgs/complements/1primate.jpg',
            videoId:'https://vimeo.com/435125475/2917f8f2ab',
            format:[854,480],
            pitch:<>
            Romain Baudoin et son projet <i>1 Primate</i>.
            </>,
            desc:<>
            Romain Baudoin et son projet <i>1 Primate</i>. Teaser 2015 filmé par Matèu Baudoin.
            <p><a href="http://hartbrut.com/1-primate/" target="_blank" rel="noopener noreferrer">Consulter la page web de Romain Baudoin</a></p>
            </>
        },
        {
            id:'jean_bona',
            title:'Jean Bona',
            sort:4,
            type:'video',
            thumbnail:'/imgs/complements/bona.jpg',
            videoId:'https://vimeo.com/437919622/478c8b7946',
            format:[1920,1080],
            pitch:<>
            Hervé Capel et Jean Bona filmés par Alexandra Astorg à Ytrac (15) en 2011.
            </>,
            desc:<>
            Hervé Capel et Jean Bona filmés par Alexandra Astorg à Ytrac (15) en 2011.
            </>
        },
        {
            id:'canicula',
            title:'canicula',
            sort:7,
            type:'sonImage',
            subTitle:'PAGANS',
            desc:(s,m)=><>
                <Playing texte={<i>Los segadors</i>} idx={0} current={s.idx} paused={!s.playing} onClick={()=>m.choosePlayer(0)}/> interprété par Canicula (Morag Munro, Jacques Baudoin, Jean Baudoin et Joan Francés Tisnèr). Titre extrait de l'album <i>Musica gascona</i> réédité en 2018 par Pagans.
            </>,
            pitch:<>
                <i>Los segadors</i> interprété par Canicula (Morag Munro, Jacques Baudoin, Jean Baudoin et Joan Francés Tisnèr).
            </>,
            src:['/sons/los_segadors.mp3'],
            image:['/imgs/complements/canicula.jpg'],
        },
        {
            id:'carlotti',
            title:'Jean-Marie Carlotti',
            sort:10,
            type:'image',
            image:['/imgs/complements/carlotti.jpg'],
            pitch:<>
            Chanteur et musicien originaire de Provence, Jean-Marie Carlotti est notamment le fondateur du groupe de musique populaire provençale Mont-Jòia dans les années 1970.
            </>,
            desc:<>
            Chanteur et musicien originaire de Provence, Jean-Marie Carlotti est notamment le fondateur du groupe de musique populaire provençale Mont-Jòia dans les années 1970. Il contribuera largement à décloisonner le folklore occitan, en lui insufflant une dynamique propre à l'esthétique pop/rock, mais aussi la chanson, dans ce qu'elle revêt de plus intimiste. Il met alors en musique ses propres textes, mais aussi des canso de troubadours ou des textes d'auteurs occitans contemporains comme Robert Lafont.
            <p><a href="https://fabrica.occitanica.eu/labasa/20879" target="_blank" rel="noopener noreferrer">Pour en savoir plus consulter la notice biographique de La Fabrica</a></p>
            </>
        },
        {
            id:'carnaval',
            title:'Carnaval indépendant de Nice',
            sort:13,
            subTitle:'Filmotec-Nice',
            type:'video',
            thumbnail:'/imgs/complements/carnaval.jpg',
            videoId:'https://vimeo.com/438820034/ce3850db96',
            format:[960,528],
            pitch:<>
            Carnaval indépendant, Nissa, Sant Roch, dimanche 20 février 1994.
            </>,
            desc:<>
            Carnaval indépendant, Nissa, Sant Roch, dimanche 20 février 1994. Collection de la Filmotec-Nice, archive n°A001.
            </>
        },
        {
            id:'zephirin',
            title:'Zéphirin Castellon',
            sort:16,
            type:'son',
            subTitle:"Maison méditerranéenne des sciences de l'homme",
            desc:(s,m)=><>
            <p><Playing texte={<i>Troisième farandole</i>} idx={0} current={s.idx} paused={!s.playing} onClick={()=>m.choosePlayer(0)}/> interprétée au fifre, au tambour et aux sonnailles dans le village de Belvédère.</p>
            <p>Enregistrement effectué par Patrick Vaillant auprès du joueur de fifre Zéphirin Castellon en 1989-1990 à Belvédère (06).</p>
            <p>Collection phonothèque de la Maison méditerranéenne des sciences de l'homme, document n° 1399_4.</p>
            <p><a href='http://phonotheque.mmsh.huma-num.fr/dyn/portal/index.seam?page=alo&aloId=9506&fonds=&cid=2079' target="_blank" rel="noopener noreferrer">Consulter la notice documentaire de cet enregistrement</a></p>
            </>,
            pitch:<>
            <p><i>Troisième farandole</i> interprétée au fifre, au tambour et aux sonnailles dans le village de Belvédère.</p>
            <p>Enregistrement effectué par Patrick Vaillant auprès du joueur de fifre Zéphirin Castellon en 1989-1990 à Belvédère (06).</p>
            <p>Collection phonothèque de la Maison méditerranéenne des sciences de l'homme, document n° 1399_4.</p>
            </>,
            src:['/sons/zephirin.mp3'],
            waveform:['/sons/zephirin.png'],
        },
        {
            id:'chancaires',
            title:'Les Chancaires',
            sort:19,
            type:'image',
            subTitle:"Lo primtemps de l'arribera",
            image:['/imgs/complements/chancaires.jpg'],
            pitch:<>
            <p>Les Chancaires (échassiers) œuvrent depuis plus de soixante ans pour la transmission de la culture locale. Danses, chants, échasses pour petits et grands, création de spectacles, école de musique, cours de langue…</p>
            </>,
            desc:(s,m)=><>
            <p>Les Chancaires (échassiers) œuvrent depuis plus de soixante ans pour la transmission de la culture locale. Danses, chants, échasses pour petits et grands, création de spectacles, école de musique, cours de langue…</p>
            <p><a href='http://loprimtempsdelarribera.com/los-chancaires-st-pe-de-leren/' target="_blank" rel="noopener noreferrer">Voir la page web</a></p>
            </>
        },
        {
            id:'troubadour',
            title:'Chant de troubadour',
            sort:22,
            type:'son',
            subTitle:'Tròba Vox',
            src:['/sons/troubadour.mp3'],
            waveform:['/sons/troubadour.png'],
            pitch:<>
            <i>Lanquan li jorn son lonc en mai</i> a été écrit par le troubadour
            Jaufré Rudel (12e siècle). Il est interprété par Maurice Moncozet et
            Véronique Condesse. L'enregistrement est extrait de l'album <i>Pas e
            Camins</i> publié par Tròba Vox en 2012.
            </>,
            desc:(s,m)=><>
            <Playing texte={<i>Lanquan li jorn son lonc en mai</i>} idx={0}
            current={s.idx} paused={!s.playing} onClick={()=>m.choosePlayer(0)}/>
            a été écrit par le troubadour Jaufré Rudel (12e siècle). Il est
            interprété par Maurice Moncozet et Véronique Condesse.
            L'enregistrement est extrait de l'album <i>Pas e Camins</i> publié par Tròba Vox en 2012.
            </>
        },
        {
            id:'la_talvera_collectage',
            title:<>Collectages CORDAE&nbsp;/ La talvera</>,
            sort:25,
            type:'son',
            subTitle:'CORDAE',
            src:['/sons/collectage_talvera.mp3','/sons/collectage_talvera_2.mp3'],
            waveform:['/sons/collectage_talvera.png','/sons/collectage_talvera_2.png'],
            pitch:<>
            <p><i>Mon paire me marida</i>, collecté par Daniel Loddo et Céline Ricard auprès d'Armand Gamel à Piquecos (82) en 1995.</p>
            <p><i>Quand èri pichonèla</i>, collecté par Daniel Loddo et Céline Ricard auprès de Léon Costes à Lestrade-et-Thouels (12) en 1995.</p>
            </>,
            desc:(s,m)=><>
            <p><Playing texte={<i>Mon paire me marida</i>} idx={0} current={s.idx} paused={!s.playing} onClick={()=>m.choosePlayer(0)}/> collecté par Daniel Loddo et Céline Ricard auprès d'Armand Gamel à Piquecos (82) en 1995.</p>
            <p><a href="http://cordae-talvera-documentation.kentika.fr/Record.htm?idlist=1&record=19182752124919009349" target="_blank" rel="noopener noreferrer">Consulter la notice documentaire de cet enregistrement</a></p>

            <p><Playing texte={<i>Quand èri pichonèla</i>} idx={1} current={s.idx} paused={!s.playing} onClick={()=>m.choosePlayer(1)}/> collecté par Daniel Loddo et Céline Ricard auprès de Léon Costes à Lestrade-et-Thouels (12) en 1995.</p>
            <p><a href="http://cordae-talvera-documentation.kentika.fr/Record.htm?idlist=4&record=19155327124919735099" target="_blank" rel="noopener noreferrer">Consulter la notice documentaire de cet enregistrement</a></p>
            </>
        },
        {
            id:'jacques_baudoin_collectage',
            title:'Collectages de Jacques Baudoin',
            sort:28,
            type:'sonImage',
            subTitle:'COMDT',
            src:['/sons/collectage_baudoin.mp3'],
            image:['/imgs/complements/mora.jpg'],
            portrait:true,
            dy:29,
            pitch:<>
            <i>Rondeau</i> collecté par Jacques Baudoin et Guy Bertrand auprès de l'accordéoniste Monsieur Mora à Mimizan (40) en 1980.
            </>,
            desc:(s,m)=><>
            <Playing texte={<i>Rondeau</i>} idx={0} current={s.idx} paused={!s.playing} onClick={()=>m.choosePlayer(0)}/> collecté par Jacques Baudoin et Guy Bertrand auprès de l'accordéoniste Monsieur Mora à Mimizan (40) en 1980.
            <p><a href="https://comdt.kentika.fr/Record.htm?idlist=77&record=19149404124919676869" target="_blank" rel="noopener noreferrer">Consulter la notice documentaire de cet enregistrement</a></p>
            </>
        },
        {
            id:'flute',
            title:'Flûte à trois trous béarnaise',
            sort:31,
            type:'sonImage',
            portrait:true,
            dy:100,
            subTitle:'COMDT',
            src:['/sons/flute.mp3'],
            image:['/imgs/complements/flute.jpg'],
            pitch:<>
            Jean Passimourt  interprète le branlo <i>airejan la vielha</i> à la flûte à trois trous et au tambourin à cordes. Enregistrement effectué à Bielle (64) dans les années 1980 par Maurice Roux. Fonds Maurice Roux.
            </>,
            desc:(s,m)=><>
            Jean Passimourt  interprète le branlo <Playing texte={<i>airejan la vielha</i>} idx={0} current={s.idx} paused={!s.playing} onClick={()=>m.choosePlayer(0)}/> à la flûte à trois trous et au tambourin à cordes. Enregistrement effectué à Bielle (64) dans les années 1980 par Maurice Roux. Fonds Maurice Roux.
            <p><a href="https://comdt.kentika.fr/Record.htm?idlist=262&record=19102736124919209189" target="_blank" rel="noopener noreferrer">Consulter la notice documentaire de cet enregistrement</a></p>
            </>
        },
        {
            id:'hedningarna',
            title:'Hedningarna',
            sort:34,
            type:'video',
            thumbnail:'/imgs/complements/hedningarna.jpg',
            videoId:'https://vimeo.com/435141973/6a02716df1',
            format:[656,480],
            pitch:<>
            Le groupe suédois Hedningarna interprète <i>Ful-valsen</i> au Roskilde Festival (Danemark) en 1993.
            </>,
            desc:<>
            Le groupe suédois Hedningarna interprète <i>Ful-valsen</i> au Roskilde Festival (Danemark) en 1993.
            </>
        },
        {
            id:'melhau',
            title:'Jan dau Melhau',
            sort:37,
            type:'image',
            image:['/imgs/complements/melhau.jpg'],
            subTitle:'La Fabrica',
            desc:<>
                <p>Jan dau Melhau est un écrivain, chanteur et conteur limousin.</p>
                <p><a href='https://fabrica.occitanica.eu/labasa/12151' target="_blank" rel="noopener noreferrer">Pour en savoir plus, consulter la notice biographique de La Fabrica.</a></p>
            </>,
            pitch:<>
                <p>Jan dau Melhau est un écrivain, chanteur et conteur limousin.</p>
            </>,
        },
        {
            id:'leon_peyrat',
            title:'Léon Peyrat',
            sort:40,
            type:'image',
            image:['/imgs/complements/peyrat.jpg'],
            subTitle:'CRMTL',
            desc:<>
                <p>Léon Peyrat (1905-1988) était un violoneux de Roux à St-Salvadour (Corrèze).</p>
                <p>Consulter <a href='http://violonpopulairemassifcentral.crmtl.fr/#Leon_Peyrat' target="_blank" rel="noopener noreferrer">la page qui lui est consacrée dans le webdocumentaire <i>Violon populaire en Massif Central</i></a> produit par le Centre Régional des Musiques Traditionnelles en Limousin pour en savoir plus.</p>
            </>,
            pitch:<>
                <p>Léon Peyrat (1905-1988) était un violoneux de Roux à St-Salvadour (Corrèze).</p>
            </>,
        },
        {
            id:'vstgm',
            title:'Va sampler ta grand-mère',
            sort:43,
            type:'image',
            portrait:true,
            dy:50,
            image:['/imgs/complements/sampler.jpg'],
            subTitle:'Hart brut',
            desc:<>
                <p><i>Va sampler ta grand-mère</i> permet à des groupes de musique
                actuelle de réaliser un travail de création à partir de collectages.</p>
                <p>Pour en savoir plus : <a href='http://hartbrut.com/va-sampler-ta-grand-mere/'
                target="_blank" rel="noopener noreferrer">Voir la page du site d'Hart Brut.</a></p>
            </>,
            pitch:<>
                <p><i>Va sampler ta grand-mère</i> permet à des groupes de musique
                actuelle de réaliser un travail de création à partir de collectages.</p>
            </>,
        },
        {
            id:'patrick_vaillant',
            title:'Patrick Vaillant',
            sort:46,
            type:'sonImage',
            subTitle:'Modal',
            desc:(s,m)=><>
                <p><Playing texte={<i>Cascalh</i>} idx={0} current={s.idx} paused={!s.playing} onClick={()=>m.choosePlayer(0)}/> est interprété par Patrick Vaillant et Lucien Massucco. Titre extrait de l'album <i>Bastian Contrari</i> édité par Modal en 2007.</p>
                <p>Patrick Vaillant en fanfare. Photogr. Patrick Paquier.</p>
            </>,
            pitch:<>
                <i>Cascalh</i> est interprété par Patrick Vaillant et Lucien Massucco.
            </>,
            src:['/sons/vaillant.mp3'],
            image:['/imgs/complements/vaillant.jpg'],
        },
        {
            id:'xavier_vidal',
            title:'Xavier Vidal',
            sort:49,
            type:'sonImage',
            portrait:true,
            dy:30,
            subTitle:'PAGANS',
            src:['/sons/vidal.mp3'],
            image:['/imgs/complements/vidal.jpg'],
            pitch:<>
            <i>Joana d'aime</i> interprété par Xavier Vidal. Extrait du CD <i>Camins de biais</i> à paraître chez Pagans.
            </>,
            desc:(s,m)=><>
            <Playing texte={<i>Joana d'aime</i>} idx={0} current={s.idx} paused={!s.playing} onClick={()=>m.choosePlayer(0)}/> interprété par Xavier Vidal. Extrait du CD <i>Camins de biais</i> à paraître chez Pagans.
            </>
        },
    ],
    entretiens:[
        {
            id:'baudoins',
            title:'Matèu & Tomàs BAUDOIN',
            sort:1,
            type:'image',
            image:['/imgs/entretiens/baudoins.jpg'],
            subTitle:'COMDT',
            desc:<>
                <p>Visionner l'intégralité de l'entretien avec Matèu & Tomàs BAUDOIN en suivant le lien ci-dessous.</p>
                <p><a href='https://comdt.kentika.fr/Record.htm?idlist=962&record=19151252124919794349'
                target="_blank" rel="noopener noreferrer">Consulter la notice sur le portail documentaire du COMDT</a></p>
            </>,
        },
        {
            id:'capel',
            title:'Hervé CAPEL',
            sort:2,
            type:'image',
            image:['/imgs/entretiens/capel.jpg'],
            subTitle:'COMDT',
            desc:<>
                <p>Visionner l'intégralité de l'entretien avec Hervé CAPEL en suivant le lien ci-dessous.</p>
                <p><a href='https://comdt.kentika.fr/Record.htm?idlist=962&record=19151251124919794339'
                target="_blank" rel="noopener noreferrer">Consulter la notice sur le portail documentaire du COMDT</a></p>
            </>,
        },
        {
            id:'caro',
            title:'Caroline DUFAU',
            sort:3,
            type:'image',
            image:['/imgs/entretiens/dufau.jpg'],
            subTitle:'COMDT',
            desc:<>
                <p>Visionner l'intégralité de l'entretien avec Caroline DUFAU en suivant le lien ci-dessous.</p>
                <p><a href='https://comdt.kentika.fr/Record.htm?idlist=962&record=19151254124919794369'
                target="_blank" rel="noopener noreferrer">Consulter la notice sur le portail documentaire du COMDT</a></p>
            </>,
        },
        {
            id:'clement',
            title:'Clément GAUTHIER',
            sort:4,
            type:'image',
            image:['/imgs/entretiens/gauthier.jpg'],
            subTitle:'COMDT',
            desc:<>
                <p>Visionner l'intégralité de l'entretien avec Clément GAUTHIER en suivant le lien ci-dessous.</p>
                <p><a href='https://comdt.kentika.fr/Record.htm?idlist=962&record=19151253124919794359'
                target="_blank" rel="noopener noreferrer">Consulter la notice sur le portail documentaire du COMDT</a></p>
            </>,
        },
        {
            id:'aelis',
            title:'Aelis LODDO',
            sort:5,
            type:'image',
            image:['/imgs/entretiens/loddo.jpg'],
            subTitle:'COMDT',
            desc:<>
                <p>Visionner l'intégralité de l'entretien avec Aelis LODDO en suivant le lien ci-dessous.</p>
                <p><a href='https://comdt.kentika.fr/Record.htm?idlist=962&record=19151248124919794209'
                target="_blank" rel="noopener noreferrer">Consulter la notice sur le portail documentaire du COMDT</a></p>
            </>,
        },
        {
            id:'vaillant',
            title:'Anaïs VAILLANT',
            sort:6,
            type:'image',
            image:['/imgs/entretiens/vaillant.jpg'],
            subTitle:'COMDT',
            desc:<>
                <p>Visionner l'intégralité de l'entretien avec Anaïs VAILLANT en suivant le lien ci-dessous.</p>
                <p><a href='https://comdt.kentika.fr/Record.htm?idlist=962&record=19151259124919794319'
                target="_blank" rel="noopener noreferrer">Consulter la notice sur le portail documentaire du COMDT</a></p>
            </>,
        },
        {
            id:'vidal',
            title:'Mickaël VIDAL',
            sort:7,
            type:'image',
            image:['/imgs/entretiens/vidal.jpg'],
            subTitle:'COMDT',
            desc:<>
                <p>Visionner l'intégralité de l'entretien avec Mickaël VIDAL en suivant le lien ci-dessous.</p>
                <p><a href='https://comdt.kentika.fr/Record.htm?idlist=962&record=19151250124919794329'
                target="_blank" rel="noopener noreferrer">Consulter la notice sur le portail documentaire du COMDT</a></p>
            </>,
        },
    ],
}
