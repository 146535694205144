import React, { Component } from 'react';
import _ from 'lodash';
import 'index.css';
import 'components/Lives.scss';
import Burger from 'components/interface/Burger';
import Data from 'assets/Data.js';
import Live from 'components/Live.js';
import Utils from 'utils/Utils';

class Lives extends Component {
    constructor(props){
        super(props);
        this.state={
            idx:0,
            choosen:null,
            offset:0
        }
        this.next=this.next.bind(this);
        this.prev=this.prev.bind(this);
        Utils.shuffle(Data.lives);
    }
    next(){
        let offset=this.state.offset-3;
        if (offset<0) offset+=Data.lives.length;
        this.setState({offset});
    }
    prev(){
        let offset=this.state.offset+3;
        if (offset>=Data.lives.length) offset-=Data.lives.length;
        this.setState({offset});
    }
    render() {
        let livesClasses="lives";
        let lives=Data.lives;
        let choosen=false;
        if (this.props.page.params.id) {
            if (_.findIndex(lives,{id:this.props.page.params.id})!==-1) {
                livesClasses+=" choosen";
                choosen=true;
            }
        }
        return <div className={livesClasses}>
            <div className="header"><div onClick={this.next}>LIVES</div></div>
            {lives.map((l,j)=>{
                let i=(j+this.state.offset)%lives.length;
                return <div
                className={"live-container live-"+i+(choosen ? (this.props.page.params.id===l.id ?" choosen" : " not-choosen") : "")}
                key={l.id}
                onClick={()=>!this.props.page.params.id && this.props.goTo('/live/'+l.id)}
                >
                <Live {...this.props}
                id={l.id}
                choosen={this.props.page.params.id===l.id}
                />
                <div className="live-title">{l.title}</div>
            </div>;})}
            <div className="footer"><div><span onClick={this.prev}>&lt;</span> <span onClick={this.next}>&gt;</span></div></div>
            <div className="btn-box left">
            <Burger {...this.props}/>
            </div>
        </div>;
    }
}
export default Lives;
