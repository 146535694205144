import React, { Component } from 'react';
import _ from 'lodash';

import 'index.css';
import './Controls.css';
import './SubtitlesContainer.scss';

class SubtitlesContainer extends Component {
    render() {
        let containerClass="controls-subtitles-container on";
        let offset=this.props.subtitlesOffset || 0;
        let subtitle=_.find(this.props.subtitles,(o)=>o.start+offset*1000<this.props.currentTime && o.end+offset*1000>this.props.currentTime);
        let text=[];
        if (subtitle) {
            let i=0;
            let tab=subtitle.text.split('\n');
            tab.map((l)=>{
                text.push(<text key={i} x="960" y={150-tab.length*50+50*i} dangerouslySetInnerHTML={{__html:l}}></text>);
                i++;
                return null;
            })
        }
        if (this.props.bottom) containerClass+=" bottom";
        return (
            <div className={containerClass}>
                <svg viewBox="0 0 1920 150">
                  {text}
                </svg>
            </div>
        );
    }
}
export default SubtitlesContainer;
