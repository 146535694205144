import Preload from 'preload-it';
import _ from 'lodash';

class AssetManager {
    constructor(o){
        if (!o) o={};
        this.cb=o.cb ? o.cb : function(){};
        this.ed= o.ed ? o.ed : null;
        this.items=[];
        this.preload=Preload();
        this.preload.oncomplete = this.oncomplete.bind(this);
        this.preload.onprogress = this.onprogress.bind(this);
        this.preload.onfetched = this.onfetched.bind(this);
        this.preload.onerror = item => {
          console.log('preload error',item);
        }
    }
    fetch(assets){
        var tab=[];
        for (var i = 0; i < assets.length; i++) {
            tab.push(assets[i].url);
            this.items.push(assets[i]);
        }
        this.preload.fetch(tab);
    }
    getAssetSrc(id){
        var item=_.find(this.items,{id:id});
        if (item && item.loaded) return item.loaded.blobUrl;
        return null;
    }
    oncomplete(items){
        //console.log(items);
        if (this.ed) this.ed.trigger('progress',100);
        this.cb();
    }
    onprogress(e){
        if (this.ed) this.ed.trigger('progress',e.progress);
    }
    onfetched(item){
        //console.log('item fetched',item);
        var it=_.find(this.items,{url:item.url});
        it.loaded=item;
        it.cb(item.blobUrl);
    }
}
export default AssetManager;
