import React from 'react';
export default <svg
   xmlns="http://www.w3.org/2000/svg"
   version="1.1"
   id="svg820"
   viewBox="0 0 1110.7179 123.947">
  <metadata
     id="metadata93632">
  </metadata>
  <g
     transform="translate(-57.53328,-582.06217)"
     id="g86">
    <title
       id="title3">boutons</title>
    <g
       aria-label="FAMILHA(S)"
       id="text88-6"
       transform="translate(24.053188,302.81271)">
      <title
         id="title93616">LOGO</title>
      <g
         id="g940"
         style={{fill:'#040404',fillOpacity:1}}>
        <path
           id="path-1"
           d="m 1117.103,396.39046 c 1.794,-2.617 3.436,-5.292 4.954,-8.071 1.519,-2.776 2.885,-5.608 4.119,-8.542 1.235,-2.932 2.315,-5.914 3.253,-8.997 0.941,-3.082 1.725,-6.211 2.361,-9.437 0.638,-3.224 1.116,-6.495 1.44,-9.857 0.323,-3.365 0.484,-6.77 0.484,-10.264 0,-3.494 -0.161,-6.899 -0.483,-10.262 -0.322,-3.362 -0.801,-6.63 -1.436,-9.855 -0.635,-3.222 -1.417,-6.349 -2.357,-9.431 -0.936,-3.081 -2.012,-6.06 -3.246,-8.992 -1.231,-2.93 -2.597,-5.76 -4.114,-8.535 -1.515,-2.778 -3.158,-5.451 -4.949,-8.065 l -0.603,-0.88 8.404,-5.953 0.62,0.918 c 3.912,5.796 7.309,12.033 10.067,18.549 2.753,6.516 4.909,13.408 6.363,20.499 1.447,7.091 2.218,14.486 2.218,22.007 0,7.519 -0.767,14.915 -2.218,22.005 -1.451,7.092 -3.61,13.983 -6.363,20.5 -2.758,6.515 -6.156,12.752 -10.069,18.548 l -0.618,0.914 -8.433,-5.914 z m -172.651,5.887 c -3.911,-5.796 -7.308,-12.033 -10.066,-18.549 -2.755,-6.518 -4.914,-13.408 -6.365,-20.5 -1.45,-7.09 -2.219,-14.487 -2.219,-22.006 0,-7.519 0.769,-14.915 2.219,-22.005 1.451,-7.091 3.61,-13.984 6.365,-20.498 2.758,-6.517 6.155,-12.755 10.066,-18.551 l 0.618,-0.914 8.435,5.917 -0.606,0.884 c -1.793,2.617 -3.437,5.293 -4.956,8.071 -1.517,2.778 -2.885,5.606 -4.117,8.541 -1.234,2.932 -2.315,5.914 -3.254,8.997 -0.941,3.082 -1.727,6.211 -2.361,9.437 -0.638,3.224 -1.117,6.495 -1.439,9.858 -0.324,3.364 -0.485,6.769 -0.485,10.263 0,3.496 0.161,6.899 0.483,10.261 0.323,3.364 0.8,6.632 1.436,9.857 0.636,3.224 1.416,6.35 2.356,9.432 0.938,3.079 2.015,6.059 3.247,8.99 1.231,2.93 2.595,5.762 4.113,8.537 1.514,2.776 3.157,5.449 4.95,8.062 l 0.604,0.88 -8.402,5.955 z" />
        <path
           d="M 102.23009,311.93259 H 55.537384 v 20.91146 h 32.656251 v 22.0573 H 55.537384 v 37.66927 H 33.480092 V 289.8753 h 68.749998 z"

           id="path905"
           />
        <path
           d="m 169.55322,350.17478 h 17.04427 l -8.30729,-28.93229 z m 29.50521,42.39584 -5.87239,-20.33854 h -30.07813 l -6.01563,20.33854 h -24.63541 l 34.08854,-104.12761 h 23.34636 l 33.94531,104.12761 z"

           id="path907"
           />
        <path
           d="m 374.80604,289.8753 v 102.69532 h -22.05729 v -44.97396 l -17.90365,46.40625 h -18.90625 l -17.76041,-46.26302 v 44.83073 h -22.0573 V 289.8753 h 22.77344 l 26.4974,65.59896 26.64062,-65.59896 z"

           id="path909"
           />
        <path
           d="M 456.30887,392.57062 H 434.25158 V 289.8753 h 22.05729 z"

           id="path911"
           />
        <path
           d="m 537.9549,370.51333 h 50.55989 v 22.05729 H 515.8976 V 289.8753 h 22.0573 z"

           id="path913"
           />
        <path
           d="m 704.67909,289.8753 h 22.05729 v 102.69532 h -22.05729 v -40.39063 h -36.66667 v 40.39063 H 645.95513 V 289.8753 h 22.05729 v 40.39063 h 36.66667 z"

           id="path916"
           />
        <path
           d="m 816.11679,350.17478 h 17.04427 l -8.30729,-28.93229 z m 29.50521,42.39584 -5.8724,-20.33854 h -30.07812 l -6.01563,20.33854 h -24.63541 l 34.08854,-104.12761 h 23.34635 l 33.94532,104.12761 z"

           id="path918"
           />
        <path
           d="m 1039.2969,330.40916 q 4.1536,0.71614 8.8802,2.00521 4.7266,1.14583 9.3099,3.00781 4.7266,1.86198 8.8802,4.4401 4.2969,2.57813 7.3047,6.01563 3.2943,3.58073 4.7266,8.30729 1.0026,2.86458 1.0026,6.58854 0,0.42969 0,0.85938 0,1.43229 -0.1433,3.00781 -0.7161,8.88021 -5.8724,14.89583 -5.013,5.8724 -12.0312,9.16667 -7.0182,3.29427 -15.1823,4.4401 -5.8724,0.85938 -11.1719,0.85938 -1.862,0 -3.8672,-0.14323 -5.8724,-0.57292 -12.0312,-2.4349 -6.1589,-2.0052 -11.7448,-5.29948 -5.586,-3.4375 -10.02615,-8.45052 -4.2968,-5.01302 -6.7317,-12.03125 l 23.20315,-6.875 q 0.5729,2.4349 2.5781,4.72657 2.1485,2.14843 4.8698,3.72395 2.8646,1.57553 6.0156,2.57813 3.2943,0.85937 6.1589,0.85937 2.0052,0.14323 5.5859,0.14323 0.2865,0 0.7162,0 3.151,0 6.0156,-0.71614 3.2943,-0.71615 5.5859,-2.4349 2.4349,-1.71875 2.4349,-5.58594 0,-2.29166 -2.5781,-4.01041 -2.4349,-1.86198 -5.7292,-3.00782 -3.2942,-1.28906 -6.4453,-2.0052 -3.151,-0.71615 -4.4401,-1.00261 -6.7317,-1.28906 -13.75,-2.86458 -7.0182,-1.71875 -12.7474,-5.01302 -5.7291,-3.4375 -9.59635,-9.02344 -3.8672,-5.72917 -4.4401,-14.89583 0,-8.16407 3.7239,-14.17969 3.72405,-6.01563 9.59645,-9.88281 5.8724,-3.86719 13.0338,-5.72917 7.1615,-2.00521 14.323,-2.00521 6.4453,0 12.7474,1.43229 6.4453,1.28906 12.1744,4.29688 5.7292,3.00781 10.4558,7.8776 4.8698,4.86979 8.3073,11.74479 l -22.3438,6.58855 q -1.4323,-2.29167 -3.8672,-4.01042 -2.4349,-1.71875 -5.4427,-2.72136 -2.8646,-1.0026 -6.0156,-1.43229 -3.0078,-0.42969 -5.7292,-0.42969 -2.5781,0 -5.4427,0.42969 -2.7213,0.28646 -5.013,1.28906 -2.1485,0.85938 -3.5807,2.57813 -1.4324,1.71875 -1.4324,4.4401 0,1.86198 1.5756,3.4375 1.7187,1.57553 4.4401,2.72136 2.8646,1.14583 6.3021,2.14844 3.5807,1.0026 7.3047,1.57552 z"

           id="path920"
           />
      </g>
    </g>
  </g>
</svg>
