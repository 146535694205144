import React, { Component } from 'react';
import _ from 'lodash';
import 'index.css';
import 'components/Osd.scss';
import Data from 'assets/Data';

class Osd extends Component {
    constructor(props){
        super(props);
        this.handleGoToLive=this.handleGoToLive.bind(this);
        this.openModal=this.openModal.bind(this);
    }
    handleGoToLive(id){
        this.props.onGoToLive();
        this.props.goTo('/live/'+id);
    }
    openModal(c){
        this.props.methods.appStateSet({modalContent:c});
    }
    render() {
        let classes="osd";
        let osd=this.props.o;
        if (osd) classes+=" show";
        return <div className={classes}>
            {osd && <div className="osd-content">
                {osd.lives && osd.lives.length>0 && <div className="osd-lives">
                    <div className="osd-live-artist">{osd.artist} en live</div>
                    <div className="osd-live-lives">
                        {osd.lives.map((l,i)=>{
                            let live=_.find(Data.lives,{id:l});
                            return <span key={l} onClick={()=>this.handleGoToLive(l)}>{live.title}</span>
                        })}
                    </div>
                </div>}
                {osd.collectages && osd.collectages.length>0 && <div className="osd-collectages">
                    <div className="osd-collectages-title">Collectages associés</div>
                    <div className="osd-collectages-collectages">
                        {osd.collectages.map((l,i)=>{
                            let collectage=_.find(Data.collectages,{id:l});
                            return <React.Fragment key={l}><span onClick={()=>this.openModal(collectage)}>{collectage.title}</span><br /></React.Fragment>
                        })}
                    </div>
                </div>}
                {osd.links && osd.links.length>0 && <div className="osd-links">
                    {osd.links.map((l,i)=>{
                        return <p key={i}><a href={l.url} target="_blank" rel="noopener noreferrer" onClick={this.props.methods.pauseAllMainVideos}>{l.label}</a></p>
                    })}
                </div>}
            </div>}
        </div>;
    }
}
export default Osd;
