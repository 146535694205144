import React, { Component } from 'react';
import 'index.css';
import 'components/Content.scss';
import Video from 'components/Video';
import Son from 'components/Son';
import Conf from 'utils/Conf';
class Content extends Component {
    constructor(props){
        super(props);
        this.state={
            idx:0,
            playing:false,
        }
        this.methods={
            choosePlayer:this.choosePlayer.bind(this),
        }
        this.son=React.createRef();
        this.handlePlay=this.handlePlay.bind(this);
        this.handlePause=this.handlePause.bind(this);
        this.reset=this.reset.bind(this);
    }
    choosePlayer(idx) {
        if (idx!==this.state.idx) this.setState({idx:idx,playing:false},()=>this.son.current.shouldPlay());
        else this.son.current.toggleShouldPlay();
    }
    handlePlay(idx) {
        this.setState({playing:true});
    }
    handlePause(idx) {
        this.setState({playing:false});
    }
    reset(idx) {
        this.setState({idx:0,playing:false});
        if (this.son.current) this.son.current.shouldPause();
    }
    render() {
        let AM=this.props.methods.am;
        let content=this.props.content;
        let desc= typeof content.desc === "function" ? content.desc(this.state,this.methods) : content.desc;
        let src='';
        let waveform='';
        if (content.src) src= content.src[this.state.idx] || content.src[0];
        if (content.waveform) waveform= content.waveform[this.state.idx] || content.waveform[0];
        let videoClass="content-content-video";
        if (this.state.playing) videoClass+=" video-playing";
        return <>
        {content.type==='video' && <div className={videoClass}>
            <div className="content-content-video-player">
                <Video
                videoId={content.videoId}
                objectId={content.id}
                objectType='content'
                format={content.format}
                active={true}
                thumbnail={AM.getAssetSrc(content.thumbnail)}
                subtitles={content.subtitles}
                onShouldPlay={this.handlePlay}
                onShouldPause={this.handlePause}
                {...this.props}
                />
            </div>
            <div className="content-content-video-desc">
                <div className="content-titre">{content.title}</div>
                {content.subTitle && <div className="content-soustitre">{content.subTitle}</div>}
                <div className="content-texte">{desc}</div>
            </div>
        </div>}
        {content.type==='son' && <div className="content-content-son">
            <div className="content-content-son-player">
                <Son
                ref={this.son}
                objectId={content.id}
                src={Conf.base+src}
                waveform={AM.getAssetSrc(waveform)}
                onPlay={this.handlePlay}
                onPause={this.handlePause}
                {...this.props}
                />
            </div>
            <div className="content-content-video-desc">
                <div className="content-titre">{content.title}</div>
                {content.subTitle && <div className="content-soustitre">{content.subTitle}</div>}
                <div className="content-texte">{desc}</div>
            </div>
        </div>}
        {content.type==='sonImage' && <div className={content.portrait ? "content-content-son portrait" : "content-content-son"}>
            <div className="content-content-son-player">
                <img className="content-content-video-player-img" alt='' src={AM.getAssetSrc(content.image[0])}/>
                <Son
                ref={this.son}
                objectId={content.id}
                src={Conf.base+src}
                onPlay={this.handlePlay}
                onPause={this.handlePause}
                {...this.props}
                />
            </div>
            <div className="content-content-video-desc">
                <div className="content-titre">{content.title}</div>
                {content.subTitle && <div className="content-soustitre">{content.subTitle}</div>}
                <div className="content-texte">{desc}</div>
            </div>
        </div>}
        {content.type==='image' && <div className={content.portrait ? "content-content-son portrait" : "content-content-son"}>
            <div className="content-content-son-player">
                <img className="content-content-video-player-img" alt='' src={AM.getAssetSrc(content.image[0])}/>
            </div>
            <div className="content-content-video-desc">
                <div className="content-titre">{content.title}</div>
                {content.subTitle && <div className="content-soustitre">{content.subTitle}</div>}
                <div className="content-texte">{desc}</div>
            </div>
        </div>}
        </>;
    }
}
export default Content;
