import React, { Component } from 'react';
import 'index.css';
import 'components/interface/Play.scss';

class Bouton extends Component {
    constructor(props){
        super(props);
        this.state={
            idx:0,
        }
    }
    render() {
        let classes="itf-play";
        if (this.props.className) classes+=" "+this.props.className;
        if (this.props.disabled) classes+=" disabled";
        if (this.props.playing) classes+=" playing";
        return <span style={this.props.style} className={classes} onClick={this.props.onClick}>
            <span className="play-shadow">
            </span>
            {!this.props.shadowOnly && <span className="play-inner">
            </span>}
            <span className="pause-shadow">
                <span className="pause-shadow-1"></span>
                <span className="pause-shadow-2"></span>
            </span>
            {!this.props.shadowOnly && <span className="pause-inner">
                <span className="pause-inner-1"></span>
                <span className="pause-inner-2"></span>
            </span>}
        </span>
    }
}
export default Bouton;
