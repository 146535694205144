import React, { Component } from 'react';
import _ from 'lodash';
import 'index.css';
import 'components/MainVideos.scss';
import Burger from 'components/interface/Burger';
import Data from 'assets/Data.js';
import MainVideo from 'components/MainVideo.js';
import Complement from 'components/Complement.js';
import Osd from 'components/Osd.js';
import Logo from 'assets/logo_familhas.js';

class MainVideos extends Component {
    constructor(props){
        super(props);
        this.state={
            idx:0,
            choosen:null,
            complementOpen:false,
            currentComplement:'',
            currentOsd:'',
        }
        this.complement=React.createRef();
        this.prev=this.prev.bind(this);
        this.next=this.next.bind(this);
        this.handleGoToLive=this.handleGoToLive.bind(this);
    }
    handleGoToLive(){
        this.props.methods.onShouldPause(this.props.page.params.id);
        this.props.methods.appStateSet({
            returnVideo:this.props.page.params.id
        });
    }
    prev(){
        let idx=_.findIndex(Data.mainVideos,{id:this.props.page.params.id});
        if(idx!==-1 && idx>0) {
            this.props.goTo('/chapitre/'+Data.mainVideos[idx-1].id);
        }
    }
    next(){
        let idx=_.findIndex(Data.mainVideos,{id:this.props.page.params.id});
        if(idx!==-1 && idx<Data.mainVideos.length-1) {
            this.props.goTo('/chapitre/'+Data.mainVideos[idx+1].id);
        }
    }
    componentDidMount(){
        console.log('main-videos');
    }
    componentDidUpdate(){
        let currentComplement='';
        let currentOsd='';
        let currentPlayer=null;
        this.props.appState.players.map((p)=>{
            if (p.type==='main' && this.props.appState.currentRoute==='/chapitre/'+p.id) {
                currentPlayer=p;
            }
            return null;
        })
        Data.mainVideos.map((v,iv)=>{
            if (v.id===this.props.page.params.id){
                if (currentPlayer) {
                    v.complements.map((c,ic)=>{
                        if (c.start<currentPlayer.currentTime && c.stop>currentPlayer.currentTime) {
                            currentComplement=_.find(Data.complements,{id:c.id});
                        }
                        return null;
                    });
                    v.osd.map((o,io)=>{
                        if (o.start<currentPlayer.currentTime && o.stop>currentPlayer.currentTime) {
                            currentOsd=o;
                        }
                        return null;
                    });
                }
            }
            return null;
        });
        if (!_.isEqual(currentComplement,this.state.currentComplement) || !_.isEqual(currentOsd,this.state.currentOsd)) {
            this.setState({currentComplement,currentOsd},this.complement.current.reset);
        }
    }
    render() {
        let mainVideosClasses="main-videos";
        if (this.props.page.params.id) mainVideosClasses+=" choosen";
        let playing=false;
        let currentPlayer=null;
        this.props.appState.players.map((p)=>{
            if (p.type==='main' && p.shouldPlay) {
                playing=true;
                currentPlayer=p;
            }
            return null;
        })
        if (playing) mainVideosClasses+=" playing";
        let currentVideoIdx=-1;
        Data.mainVideos.map((v,iv)=>{
            if (v.id===this.props.page.params.id){
                currentVideoIdx=iv;
            }
            return null;
        });
        let prevClass="main-videos-nav-prev";
        if (currentVideoIdx===0) prevClass+=" disabled";
        let nextClass="main-videos-nav-next";
        if (currentVideoIdx===Data.mainVideos.length-1) nextClass+=" disabled";
        return <div className={mainVideosClasses}>
            <div className="main-videos-video-container">
                {Data.mainVideos.map((v,i)=>{
                    let vClasses="main-videos-video video"+i;
                    let player=_.find(this.props.appState.players,{id:v.videoId});
                    if (this.props.page.params.id===v.id) vClasses+=" choosen";
                    if (this.props.page.params.id && v.id!==this.props.page.params.id) vClasses+=" not-choosen";
                    if (player && player.shouldPlay) vClasses+=" playing";
                    return <div className={vClasses} key={v.id} onClick={()=>!this.props.page.params.id && this.props.goTo('/chapitre/'+v.id)}>
                        <MainVideo
                        {...this.props}
                        complementOpen={this.state.complementOpen}
                        id={v.id}
                        choosen={this.props.page.params.id===v.id}
                        next={this.next}
                        last={currentVideoIdx===Data.mainVideos.length-1}
                        />
                    </div>
                })}
            </div>
            <Complement
                ref={this.complement}
                {...this.props}
                c={this.state.currentComplement}
                onClose={()=>this.props.methods.handlePlayInPlace(currentPlayer)}
                onActivate={()=>this.props.methods.handlePauseInPlace(currentPlayer)}
            />
            <Osd
                {...this.props}
                o={this.state.currentOsd}
                onGoToLive={this.handleGoToLive}
                onOpenCollectage={()=>this.props.methods.handlePauseInPlace(currentPlayer)}
                onCloseCollectage={()=>this.props.methods.handlePlayInPlace(currentPlayer)}
            />
            <div className="main-videos-nav">
                <div className={prevClass} onClick={this.prev}></div>
                <div className="main-videos-nav-sommaire" onClick={(e)=>{e.stopPropagation();this.props.goTo('/sommaire/');}}></div>
                <div className={nextClass} onClick={this.next}></div>
            </div>
            <div className="logo">{Logo}</div>
            <div className="btn-box left">
            <Burger {...this.props} color={this.props.page.params.id ? 'beige' : false}/>
            </div>
    </div>;
    }
}
export default MainVideos;
