import React, { Component } from 'react';
import 'index.css';
import 'components/interface/Burger.scss';

class Burger extends Component {
    constructor(props){
        super(props);
        this.state={
            idx:0,
        }
        this.handleClick=this.handleClick.bind(this);
    }
    handleClick(){
        this.props.methods.appStateSet({maindDrawerOpen:!this.props.appState.maindDrawerOpen});
    }
    render() {
        let classes="itf-burger";
        if (this.props.disabled) classes+=" disabled";
        if (this.props.color) classes+=" "+this.props.color;
        return <div className={classes} onClick={!this.props.disabled ? this.handleClick : undefined}>
            <div className="burger-shadow">
                <div className="burger-line1">
                </div>
                <div className="burger-line2">
                </div>
                <div className="burger-line3">
                </div>
            </div>
            <div className="burger-inner">
                <div className="burger-line1">
                </div>
                <div className="burger-line2">
                </div>
                <div className="burger-line3">
                </div>
            </div>
        </div>
    }
}
export default Burger;
