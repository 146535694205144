import React, { Component } from 'react';
import 'index.css';
import 'components/Complement.scss';
import Bouton from 'components/interface/Bouton';
import Son from 'components/Son';
import Play from 'components/interface/Play';
import Content from 'components/Content';

class Complement extends Component {
    constructor(props){
        super(props);
        this.state={
            active:false,
            itemActive:false,
            closed:false,
        }
        this.handleActivate=this.handleActivate.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.reset=this.reset.bind(this);
        this.content=React.createRef();
    }
    handleActivate(){
        this.props.onActivate();
        this.setState({active:true});
    }
    handleClose(){
        let complement=this.props.c;
        if (complement.type==='video') this.props.methods.onShouldPause(complement.id);
        this.content.current.reset();
        this.props.onClose();
        this.setState({active:false,closed:true});
    }
    onComponentDidUpdate(){
        if (!this.props.c) this.reset();
    }
    reset(){
        this.props.onClose();
        this.setState({active:false,closed:false});
    }
    render() {
        let AM=this.props.methods.am;
        let classes="complement";
        let complement=this.props.c;
        if (complement && !this.state.closed) classes+=" show";
        if (complement && this.state.active) classes+=" active";
        if (complement && complement.portrait) classes+=" complement-portrait";
        return <div className={classes}>
        <Bouton text='X' className='complement-fermer' onClick={this.handleClose}/>
        <div className="complement-container">
            <Content ref={this.content} content={complement} {...this.props}/>
        </div>
        {complement.type==='video' && <Bouton text='Voir' className='complement-activate' onClick={this.handleActivate}/>}
        {(complement.type==='son' || complement.type==='sonImage') && <Bouton text='Écouter' className='complement-activate' onClick={this.handleActivate}/>}
        {complement.type==='image' && <Bouton text='En savoir plus' className='complement-activate' onClick={this.handleActivate}/>}
        {complement.type==='video' && <div className="complement-preview-video-thumb">
            <img className="complement-thumb" alt='' src={AM.getAssetSrc(complement.thumbnail)}/>
            <Play onClick={this.handleActivate}/>
        </div>}
        {complement.type==='son' && <div className="complement-preview-son-thumb">
            <Son
            waveform={AM.getAssetSrc(complement.waveform[0])}
            thumb={true}
            objectId={complement.id+'_thumb'}
            />
        </div>}
        {complement.type==='sonImage' && <div className="complement-preview-son-thumb">
            <img className="content-content-video-player-img" alt='' src={AM.getAssetSrc(complement.image[0])}/>
        </div>}
        {complement.type==='image' && <div className="complement-preview-son-thumb">
            <img className="content-content-video-player-img" alt='' src={AM.getAssetSrc(complement.image[0])}/>
        </div>}
        <div className="complement-preview-video-desc">
            <div className="complement-titre">{complement.title}</div>
            {complement.subTitle && <div className="complement-soustitre">{complement.subTitle}</div>}
            <div className="complement-texte">{complement.pitch}</div>
        </div>
        </div>;
    }
}
export default Complement;
