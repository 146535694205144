import React, { Component } from 'react';
import _ from 'lodash';
import 'index.css';
import 'components/Live.scss';
import Bouton from 'components/interface/Bouton';
import Data from 'assets/Data.js';
import Video from 'components/Video';

class Live extends Component {
    constructor(props){
        super(props);
        this.state={
            idx:0,
            show:true,
        }
    }
    componentDidMount(){
        console.log('mount',this.props.id);
        let live=_.find(Data.lives,{id:this.props.id});
        let players=this.props.appState.players;
        let player=_.find(players,{id:live.id});
        if (!player) {
            players.push({id:live.id, videoId:live.videoId, shouldPlay:false, positionCanUpdate:true, currentTime:0, position:0});
            this.props.methods.appStateSet({players});
        }
    }
    render() {
        let AM=this.props.methods.am;
        let live=_.find(Data.lives,{id:this.props.id});
        let classes="live";
        let descClasses="live-desc";
        let retourClasses="live-retour";
        let players=this.props.appState.players;
        let player=_.find(players,{id:live.id});
        if (this.props.choosen) classes+=" choosen";
        if (this.props.choosen && player && player.currentTime<10) descClasses+=" show";
        if (this.props.choosen && player && this.props.appState.returnVideo) retourClasses+=" show";
        return <div className={classes}>
            <div className="live-main-mask"></div>
            <Video
            videoId={live.videoId}
            objectId={live.id}
            objectType='live'
            active={this.props.choosen}
            thumbnail={AM.getAssetSrc(live.thumbnail)}
            subtitles={live.subtitles}
            format={live.format}
            onShouldPause={()=>this.props.goTo('/lives/')}
            onEnd={()=>this.props.appState.returnVideo ? this.props.goTo('/chapitre/'+this.props.appState.returnVideo+"?autoplay") : this.props.goTo('/lives/')}
            playOpacity={0}
            playLeft={15}
            playBottom={60}
            {...this.props}
            />
            <div className={descClasses}>
                <div className="live-content-titre">{live.title}</div>
                <div className="live-content-desc">
                    {live.desc}
                </div>
            </div>
            <div className={retourClasses}>
                <Bouton text='Retour au film' className='live-retour-btn' onClick={()=>this.props.goTo('/chapitre/'+this.props.appState.returnVideo+"?autoplay")}/>
            </div>
        </div>;
    }
}
export default Live;
