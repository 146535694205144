import React, { Component } from 'react';
import 'index.css';
import 'components/Son.scss';
import Play from 'components/interface/Play';
import ProgressBar from 'components/interface/ProgressBar';

class Son extends Component {
    constructor(props){
        super(props);
        this.state={
            shouldPlay:false,
            playing:false,
            position:0,
            currentTime:0,
            positionCanUpdate:true,
        }
        this.sound=React.createRef();
        this.waveform=React.createRef();
        this.handlePlay=this.handlePlay.bind(this);
        this.handlePause=this.handlePause.bind(this);
        this.shouldPlay=this.shouldPlay.bind(this);
        this.shouldPause=this.shouldPause.bind(this);
        this.toggleShouldPlay=this.toggleShouldPlay.bind(this);
        this.handleTimeUpdate=this.handleTimeUpdate.bind(this);
        this.handleLoaded=this.handleLoaded.bind(this);
        this.handlePositionUpdate=this.handlePositionUpdate.bind(this);
        this.handleTargetMove=this.handleTargetMove.bind(this);
        this.handleTargetMoveStart=this.handleTargetMoveStart.bind(this);
        this.handleTargetMoveEnd=this.handleTargetMoveEnd.bind(this);
        this.handleEnd=this.handleEnd.bind(this);
        this.moveWaveform=this.moveWaveform.bind(this);
        this.interval=0;
    }
    componentDidMount(){
        console.log('mount son',this.props.objectId);
    }
    componentWillUnmount() {
        console.log('unmount son',this.props.objectId);
        clearInterval(this.interval);
    }
    toggleShouldPlay(){
        if (this.state.shouldPlay) this.shouldPause();
        else this.shouldPlay();
    }
    shouldPlay(){
        this.sound.current.volume=this.props.appState.mainVolume
        this.setState({shouldPlay:true});
        this.sound.current.play();
        this.props.onPlay && this.props.onPlay();
    }
    shouldPause(){
        this.setState({shouldPlay:false});
        this.sound.current.pause();
        this.props.onPause && this.props.onPause();
    }
    handlePlay(){
        this.setState({playing:true});
        this.interval = setInterval(this.handleTimeUpdate, 30);
    }
    handleEnd(){
        this.shouldPause();
        this.handlePositionUpdate(0);
    }
    handlePause(){
        clearInterval(this.interval);
        this.setState({playing:false});
    }
    handleTimeUpdate(){
        if (this.sound.current) {
            let currentTime= this.sound.current.currentTime;
            let position= this.sound.current.currentTime/this.state.duration;
            if (this.state.positionCanUpdate) this.moveWaveform(currentTime);
            if (this.sound.current.volume!==this.props.appState.mainVolume) this.sound.current.volume=this.props.appState.mainVolume;
            this.setState({currentTime,position});
        }
    }
    handlePositionUpdate(p){
        let position=p;
        let currentTime=this.state.duration*p;
        this.moveWaveform(currentTime);
        this.setState({position,currentTime});
        this.sound.current.currentTime=currentTime;
    }
    moveWaveform(currentTime){
        if (this.props.waveform) this.waveform.current.style.left=(50 - currentTime*100/7.3988)+'%';
    }
    handleTargetMove(p){
        let currentTime=this.state.duration*p;
        this.moveWaveform(currentTime);
    }
    handleTargetMoveStart(p){
        this.setState({positionCanUpdate:false});
    }
    handleTargetMoveEnd(p){
        this.setState({positionCanUpdate:true});
    }
    handleLoaded(e){
        let duration= this.sound.current.duration ;
        this.setState({duration});
    }
    render() {
        let classes="son";
        if (this.props.thumb) classes+=" thumb";
        if (this.props.waveform) classes+=" background";
        return <div className={classes}>
                {!this.props.thumb && <audio
                ref={this.sound}
                src={this.props.src}
                onPlay={this.handlePlay}
                onPause={this.handlePause}
                onTimeUpdate={this.handleTimeUpdate}
                onLoadedMetadata={this.handleLoaded}
                onEnded={this.handleEnd}
                />}
                {this.props.waveform && <img ref={this.waveform} alt='' src={this.props.waveform}/>}
                {!this.props.thumb && this.props.waveform && <div className='playhead'></div>}
                {!this.props.thumb && <Play playing={this.state.shouldPlay} onClick={this.toggleShouldPlay}/>}
                {!this.props.thumb && this.state.duration && <ProgressBar
                    {...this.props}
                    position={this.state.position}
                    currentTime={this.state.currentTime}
                    duration={this.state.duration}
                    onPositionUpdate={(p) => this.handlePositionUpdate(p)}
                    onTargetMoveStart={this.handleTargetMoveStart}
                    onTargetMoveEnd={this.handleTargetMoveEnd}
                    onTargetMove={(p) => this.handleTargetMove(p)}
                />}
        </div>;
    }
}
export default Son;
