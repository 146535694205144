import React, { Component } from 'react';
import 'index.css';
import 'components/Countdown.scss';

class Countdown extends Component {
    constructor(props){
        super(props);
        this.state={
            active:false,
        }
        this.start=this.start.bind(this);
        this.stop=this.stop.bind(this);
    }
    start(){
        this.setState({active:false},()=>setTimeout(()=>this.setState({active:true}),10));
    }
    stop(){
        this.setState({active:false});
    }
    render() {
        let classes="countdown";
        if (this.state.active) classes+=" active";
        return <div className={classes} onTransitionEnd={this.props.onEnd}></div>;
    }
}
export default Countdown;
