import React, { Component } from 'react';
import 'index.css';
import 'components/Mentions.scss';
import Burger from 'components/interface/Burger';
import Data from 'assets/Data.js';

class Mentions extends Component {
    constructor(props){
        super(props);
        this.state={
            idx:0,
        }
        this.next=this.next.bind(this);
        this.prev=this.prev.bind(this);
    }
    next(){
        this.setState({idx:Math.min(Data.mentions.length-1,this.state.idx+1)});
    }
    prev(){
        this.setState({idx:Math.max(0,this.state.idx-1)});
    }
    render() {
        let mentionsClasses="mentions";
        return <div className="mentions-main">
            <div className="mentions-container">
                <div className="mentions-shadow">
                </div>
                <div className={mentionsClasses}>
                    <div className="mentions-contour"></div>
                    <div className="mentions-text">
                        {Data.mentions}
                    </div>
                </div>
            </div>
            <div className="btn-box left">
            <Burger {...this.props}/>
            </div>
        </div>;
    }
}
export default Mentions;
