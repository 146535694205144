import React, { Component } from 'react';
import _ from 'lodash';
import 'index.css';
import './Router.scss';
import { Path } from 'path-parser';
import Qs from 'qs';

class Router extends Component {
    constructor(props) {
        super(props);
        this.state={
            pages:[]
        }
        this.base='';
        if (process.env.NODE_ENV === 'production') {
            this.base='/familhas';
        }
        this.beforeExit=()=>true;
        this.go=this.go.bind(this);
        this.removePrevious=this.removePrevious.bind(this);
        this.handleCanExit=this.handleCanExit.bind(this);
        this.routerRef=React.createRef();
    }
    handleCanExit(cb){
        this.beforeExit=cb;
    }
    componentDidMount() {
        this.props.history.listen((o) => {
            let location=o.location;
            let action=o.action;
            console.log(location,action);
            if (action!=='REPLACE') {
                let url=location.pathname.replace(this.base,'');
                let query=Qs.parse(location.search, { ignoreQueryPrefix: true });
                this.go(url,query);
            }
        });
        let url=window.location.pathname.replace(this.base,'');
        let query=Qs.parse(window.location.search, { ignoreQueryPrefix: true });
        console.log(url,query);
        this.go(url,query);
    }
    removePrevious(e){
        console.log(e.target.parentNode,this.routerRef.current);
        if (e.target.parentNode===this.routerRef.current) {
            let pages=this.state.pages;
            let toDelete=_.filter(pages,{type:'previous'});
            toDelete.map((p)=>{
                if (!p.keep) {
                    let idx=_.findIndex(pages,p);
                    pages.splice(idx,1);
                }
                return null;
            })
        }
    }
    go(url,query){
        if (Object.keys(query).length>0) {
            this.props.history.replace({
              pathname: this.base+url,
              search: ''
            });
        }
        if(this.beforeExit()){
            this.beforeExit=()=>true;
            let newPage, currentPage, currentPageCopy, newRoute, newParams, pages;
            pages=this.state.pages;
            if(url !== this.state.current) {
                newRoute=_.find(this.props.routes,(r)=>{
                    let path = new Path(r.url);
                    newParams=path.test(url);
                    return newParams;
                });
                if (!newRoute){
                    if (this.props.routes.length>0)
                       this.props.history.replace(this.props.routes[0].url);
                } else {
                    currentPage=_.find(this.state.pages,{type:'current'});
                    if (currentPage) {
                        currentPageCopy=_.cloneDeep(currentPage);
                        if (newRoute.page !== currentPage.id){
                            currentPage.type='previous';
                        }
                    }
                    let p=_.find(this.props.pages,{id:newRoute.page});
                    let t=_.find(this.props.templates,{id:p.template});
                    newPage={
                        url:url,
                        query:query,
                        id:p.id,
                        keep:p.keep,
                        component:t.component,
                        params:newParams,
                        type:'current',
                        cb:newRoute.cb,
                        exitCb:newRoute.exitCb,
                        ref:React.createRef(),
                    };
                    let samePage=_.find(pages,{id:p.id});
                    if (samePage) {
                        samePage.url=newPage.url;
                        samePage.query=newPage.query;
                        samePage.keep=newPage.keep;
                        samePage.params=newPage.params;
                        samePage.type=newPage.type;
                        samePage.cb=newRoute.cb;
                        samePage.exitCb=newRoute.exitCb;
                    }
                    else pages.push(newPage);
                    console.log(newRoute.page,newPage);
                    this.setState({pages},()=>{
                        console.log('page change',currentPageCopy);
                        if (currentPageCopy) currentPageCopy.exitCb && currentPageCopy.exitCb(currentPage);
                        newPage.cb && newPage.cb(newPage);
                        return;
                    });
                }
            }
            this.props.onRouteHasChanged(url);
        } else {
            this.props.history.replace(url);
        }
    }
    render() {
        return (
            <div className="router" ref={this.routerRef}>
                {this.state.pages.map((p)=>{
                    let pClass="router-route router-"+p.type;
                    let C=p.component;
                    if (p.url)
                        return <div
                        className={pClass}
                        key={p.id}
                        onAnimationEnd={(p.type==='current') ? this.removePrevious : undefined}
                        >
                            <C
                            ref={p.ref}
                            goTo={this.props.goTo}
                            key={p.id}
                            page={p}
                            appState={this.props.appState}
                            methods={this.props.methods}
                            canExit={this.handleCanExit}
                            />
                        </div>;
                    else
                        return '';
                })}
            </div>
        );
    }
}
export default Router;
