import React, { Component } from 'react';
import _ from 'lodash';
import 'index.css';
import 'components/MainVideo.scss';
import Data from 'assets/Data.js';
import Video from 'components/Video';
import Conf from 'utils/Conf';

class MainVideo extends Component {
    constructor(props){
        super(props);
        this.state={
            idx:0,
            show:true,
        }
        this.timeoutId=0;
        this.handleMouseMove=this.handleMouseMove.bind(this);
        this.handleControlsClick=this.handleControlsClick.bind(this);
        this.openModal=this.openModal.bind(this);
    }
    componentDidMount(){
        console.log('mount',this.props.id);
        let chapter=_.find(Data.mainVideos,{id:this.props.id});
        let players=this.props.appState.players;
        let player=_.find(players,{id:chapter.videoId});
        if (!player) {
            players.push({id:chapter.id, videoId:chapter.videoId, type:'main', shouldPlay:false, positionCanUpdate:true, currentTime:0, position:0});
            this.props.methods.appStateSet({players});
        }
        document.addEventListener('mousedown',this.handleMouseMove);
        document.addEventListener('mousemove',this.handleMouseMove);
    }
    componentWillUnmount() {
        clearTimeout(this.timeoutId);
        document.removeEventListener('mousedown',this.handleMouseMove);
        document.removeEventListener('mousemove',this.handleMouseMove);
    }
    handleMouseMove(e){
        var that=this;
        clearTimeout(this.timeoutId);
        if (!this.state.show) {
            this.setState({show:true});
        }
        this.timeoutId=setTimeout(function () {
            that.setState({show:false});
        }, Conf.timeBeforeFade);
    }
    openModal(c){
        this.props.methods.appStateSet({modalContent:c});
    }
    handleControlsClick(e,id){
        console.log('controlsClick',this.props.appState.clickDisabled);
        !this.props.appState.clickDisabled && e.target===e.currentTarget && this.props.choosen && this.props.methods.onToggleShouldPlay(id);
    }
    render() {
        let AM=this.props.methods.am;
        let chapter=_.find(Data.mainVideos,{id:this.props.id});
        let classes="main-video";
        if (this.props.choosen) classes+=" choosen";
        let player=_.find(this.props.appState.players,{id:chapter.id});
        if (player && player.shouldPlay) classes+=" playing";
        return <div className={classes}>
            <div className="main-video-main-mask"></div>
            {player && <div className="main-video-video-container">
                <Video
                videoId={chapter.videoId}
                objectId={chapter.id}
                objectType='mainVideo'
                active={this.props.choosen}
                thumbnail={AM.getAssetSrc(chapter.thumbnail)}
                subtitles={chapter.subtitles}
                subtitlesOffset={chapter.subtitlesOffset}
                onEnd={(e) => {setTimeout(()=>this.props.last ? this.props.goTo('/credits/') : this.props.next(),3000);return null;}}
                playIdleOpacity={1}
                playIdleLeft="calc(50% - 50px)"
                playIdleBottom="calc(50% - 50px)"
                playIdleSize={100}
                playActiveOpacity={1}
                playActiveLeft={0}
                playActiveBottom={15}
                playActiveSize={60}
                {...this.props}
                />
                <div className="main-video-text-container">
                    <div className="main-video-text-titre">
                        {chapter.title}<span> / {chapter.translation} {this.props.appState.clickDisabled && <span>ok</span> }</span>
                    </div>
                    <div className="main-video-text-soustitre">{chapter.translation}</div>
                </div>
                <div className="main-video-complements">
                    {chapter.complements.map((c,i)=>{
                        let complement=_.find(Data.complements,{id:c.id});
                        return <p onClick={()=>this.openModal(complement)} key={i}>
                            {complement.title}
                        </p>
                    })}
                </div>
            </div>}
        </div>;
    }
}
export default MainVideo;
