export default `
1
00:01:23,760 --> 00:01:24,800
Ma famille...

2
00:01:27,520 --> 00:01:30,600
Elle doit ressembler
à une famille normale...

3
00:01:31,240 --> 00:01:35,000
Mes parents sont professeurs
de l'Éducation nationale.

4
00:01:35,760 --> 00:01:39,600
Pendant toutes les vacances
et les week-ends,

5
00:01:41,560 --> 00:01:46,720
ils étaient engagés dans la vie associative
et l'éducation populaire :

6
00:01:48,000 --> 00:01:50,920
ateliers de danse,
de chant et de musique...

7
00:01:52,080 --> 00:01:56,800
J'ai toujours entendu parler de théâtre,
de comités des fêtes,

8
00:01:57,240 --> 00:02:00,880
de monter une estrade,
organiser un spectacle...

9
00:02:01,640 --> 00:02:05,480
Mes parents ont toujours été actifs
dans la vie du village,

10
00:02:06,280 --> 00:02:10,000
à tisser le tissu social
entre les vieux et les jeunes.

11
00:02:10,760 --> 00:02:15,080
Toujours à faire quelque chose de festif
qui peut lier les gens.
`
