import React, { Component } from 'react';
import 'index.css';
import './Controls.css';
import './ProgressBar.scss';

class ProgressBar extends Component {
    constructor(props){
        super(props);
        this.state={
            targetPosition:0,
            targetOn:false
        }
        this.handleMouseDown=this.handleMouseDown.bind(this);
        this.handleMouseUp=this.handleMouseUp.bind(this);
        this.handleMouseMove=this.handleMouseMove.bind(this);
        this.progressBarRef = React.createRef();
    }
    componentDidMount() {
        document.addEventListener('mouseup',this.handleMouseUp);
        document.addEventListener('mousemove',this.handleMouseMove);
    }
    componentWillUnmount() {
        document.removeEventListener('mouseup',this.handleMouseUp);
        document.removeEventListener('mousemove',this.handleMouseMove);
    }
    handleMouseDown(e){
        let node = this.progressBarRef.current;
        let rect = node.getBoundingClientRect();
        let p=Math.min(1,Math.max(0,(e.clientX-rect.left)/rect.width));
        this.setState({moveStartPosition:p, targetPosition:p, targetCurrentTime:p*this.props.duration, targetOn:true});
        this.props.onTargetMoveStart && this.props.onTargetMoveStart();
        this.props.methods.appStateSet({clickDisabled:true});
    }
    handleMouseMove(e){
        if (this.state.targetOn) {
            const node = this.progressBarRef.current;
            let rect = node.getBoundingClientRect();
            let mp=(e.clientX-rect.left)/rect.width;
            let p=Math.min(1,Math.max(0,this.state.moveStartPosition+(mp-this.state.moveStartPosition)));
            this.setState({
                targetPosition:p,
                targetCurrentTime:p*this.props.duration,
            });
            this.props.onTargetMove && this.props.onTargetMove(p);
        }
    }
    handleMouseUp(e){
        if (this.state.targetOn) {
            this.setState({targetOn:false});
            this.props.onPositionUpdate(this.state.targetPosition);
            this.props.onTargetMoveEnd && this.props.onTargetMoveEnd();
        }
        setTimeout(()=>this.props.methods.appStateSet({clickDisabled:false},()=>console.log('clickDisabled',false)),100);
    }
    toHHMMSS (s) {
        var sec_num = parseInt(s, 10); // don't forget the second param
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        return minutes+':'+seconds;
    }
    render() {
        let progressStyle={width:(this.props.position*100)+'%'};
        let targetStyle={left:'calc('+(this.state.targetPosition*100)+'% - 5px)'};
        let targetClass="controls-progressbar-target";
        let time=this.toHHMMSS(this.props.currentTime);
        let total=this.toHHMMSS(this.props.duration);
        if (this.state.targetOn) {
            targetClass+=" on";
            time=this.toHHMMSS(this.state.targetCurrentTime);
        }
        return (
            <div className="controls-progressbar-container">
                <div className="controls-progressbar-background">
                    <div className="controls-progressbar-progress" style={progressStyle}></div>
                    <div className={targetClass} style={targetStyle}></div>
                </div>
                <div className="controls-progressbar-mask"
                    ref={this.progressBarRef}
                    onMouseDown={this.handleMouseDown}
                    ></div>
                <div className="controls-progressbar-currenttime">{time} / {total}</div>
            </div>
        );
    }
}
export default ProgressBar;
