export default `
1
00:01:56,560 --> 00:01:59,560
Pourquoi une communauté
a besoin d'images ?

2
00:02:00,120 --> 00:02:03,720
Pourquoi avons-nous tous besoin
de nous voir à l'écran,

3
00:02:04,200 --> 00:02:06,200
d'avoir une représentation,

4
00:02:06,600 --> 00:02:09,720
d'avoir une connaissance
et une reconnaissance de ce que nous sommes ?

5
00:02:10,360 --> 00:02:14,480
Chanter est une forme d'expression
mais nous voulons la relier

6
00:02:14,800 --> 00:02:18,200
à tout ce qui suit.

7
00:02:19,000 --> 00:02:20,160
À un imaginaire.

8
00:02:20,560 --> 00:02:22,920
Un imaginaire et aussi une façon...

9
00:02:23,400 --> 00:02:28,480
Comment nous portons le chant
et la langue sur la scène ?

10
00:02:29,040 --> 00:02:32,600
Comment nous portons ça sur Internet,
dans les clips ?

11
00:02:33,080 --> 00:02:37,440
D'autant plus que nous, en Occitanie,
nous avons tout à inventer, à oser.

`
