import React, { Component } from 'react';
import 'index.css';
import './Controls.css';
import './MuteBtn.scss';

class MuteBtn extends Component {
    constructor(props){
        super(props);
        this.state={
            targetVolume:0,
            targetOn:false,
        }
        this.handleMouseDown=this.handleMouseDown.bind(this);
        this.handleMouseUp=this.handleMouseUp.bind(this);
        this.handleMouseMove=this.handleMouseMove.bind(this);
        this.handleWheel=this.handleWheel.bind(this);
        this.volumeLevelRef = React.createRef();
    }
    componentDidMount() {
        document.addEventListener('mouseup',this.handleMouseUp);
        document.addEventListener('mousemove',this.handleMouseMove);
    }
    componentWillUnmount() {
        document.removeEventListener('mouseup',this.handleMouseUp);
        document.removeEventListener('mousemove',this.handleMouseMove);
    }
    handleMouseDown(e){
        let node = this.volumeLevelRef.current;
        let rect = node.getBoundingClientRect()
        let v=Math.min(1,Math.max(0,-(rect.left-e.clientX)/rect.width));
        this.setState({targetVolume:v, targetOn:true});
        this.props.onVolumeChange(v);
    }
    handleMouseMove(e){
        if (this.state.targetOn) {
            const node = this.volumeLevelRef.current;
            let rect = node.getBoundingClientRect()
            let v=Math.min(1,Math.max(0,-(rect.left-e.clientX)/rect.width));
            this.setState({
                targetVolume:v
            });
            this.props.onVolumeChange(v);
        }
    }
    handleWheel(e){
        e.preventDefault();
        let v = Math.max(0,Math.min(1,this.props.volume+e.deltaY * -0.01));
        this.setState({
            targetVolume:v
        });
        this.props.onVolumeChange(v);
    }
    handleMouseUp(e){
        if (this.state.targetOn) {
            this.setState({targetOn:false});
            this.props.onVolumeChange(this.state.targetVolume);
        }
    }
    render() {
        let btnClass="controls-mute-btn controls-btn";
        let clickHandler=this.props.onMute;
        if (this.props.muted) {
            btnClass+=" muted";
            clickHandler=this.props.onUnMute;
        }
        let volumeStyle={width:(this.props.volume*100)+'%'};
        let targetStyle={left:'calc('+(this.state.targetVolume*100)+'% - 5px)'};
        let targetClass="controls-mute-btn-volume-target";
        let volumeClass="controls-mute-btn-volume";
        if (this.state.targetOn) {
            targetClass+=" on";
            volumeClass+=" show";
        }
        return (
            <span className={btnClass} onWheel={this.handleWheel}>
                <div className={volumeClass}>
                    <div className="controls-mute-btn-volume-container">
                        <div className="controls-mute-btn-volume-container-inner">
                            <div className="controls-mute-btn-volume-background"></div>
                            <div className="controls-mute-btn-volume-level" style={volumeStyle}></div>
                            <div className={targetClass} style={targetStyle}></div>
                            <div className="controls-mute-btn-volume-mask"
                                ref={this.volumeLevelRef}
                                onMouseDown={this.handleMouseDown}
                                ></div>
                        </div>
                    </div>
                </div>
                <div className="controls-mute-btn-mute" onClick={clickHandler}>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15">
                  <path id="Union_1" data-name="Union 1" d="M-795.8,4105H-803v-7h7.2l4.8-4v15Z" transform="translate(803 -4094)"/>
                </svg>
                </div>
            </span>
        );
    }
}
export default MuteBtn;
