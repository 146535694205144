import React, { Component } from 'react';
import 'index.css';
import 'components/Accueil.scss';
import Bouton from 'components/interface/Bouton';
import Burger from 'components/interface/Burger';
import Data from 'assets/Data.js';

class Accueil extends Component {
    constructor(props){
        super(props);
        this.state={
            idx:0,
        }
        this.next=this.next.bind(this);
        this.prev=this.prev.bind(this);
    }
    next(){
        this.setState({idx:Math.min(Data.edito.length-1,this.state.idx+1)});
    }
    prev(){
        this.setState({idx:Math.max(0,this.state.idx-1)});
    }
    render() {
        let editoClasses="edito";
        editoClasses+=" current-"+this.state.idx;
        return <div className="accueil">
            <div className="edito-container">
                <div className="edito-shadow">
                </div>
                <div className={editoClasses}>
                    {Data.edito.map((p,i)=>{
                        let classes="edito-page";
                        classes+=" page-"+i;
                        return <div className={classes} key={i}>
                            <div className="edito-contour"></div>
                            <div className="edito-text">
                                {p.title && <h2>{p.title}</h2>}
                                {p.text}
                                {i===Data.edito.length-1 && <div style={{textAlign:'right'}}>
                                    <Bouton text='commencer' onClick={()=>this.props.goTo('/chapitre/naisser')}/>
                                </div>
                                }
                            </div>
                        </div>
                    })}
                    <div className="btn-box">
                    <Bouton text='Précédent' disabled={this.state.idx===0} onClick={this.prev}/>
                    <Bouton text='Suivant' disabled={this.state.idx===Data.edito.length-1} onClick={this.next}/>
                    </div>
                </div>
            </div>
            <div className="btn-box left">
            <Burger {...this.props}/>
            </div>
        </div>;
    }
}
export default Accueil;
