export default `
1
00:04:15,560 --> 00:04:17,800
J'ai toujours chanté en occitan.

2
00:04:19,040 --> 00:04:22,680
L'association où j'ai été nourrie
à notre culture

3
00:04:23,560 --> 00:04:26,160
s'appelle Los Chancaires
de Saint-Pé-de-Léren.

4
00:04:26,400 --> 00:04:28,440
C'est pour ça que je te parle d'échasses.

5
00:04:28,880 --> 00:04:29,760
Les échasses...

6
00:04:32,360 --> 00:04:36,840
Marcher sur des échasses, oui,
mais aussi danser, jouer de la musique,

7
00:04:38,560 --> 00:04:40,840
tout pour fabriquer un spectacle.

8
00:04:41,360 --> 00:04:46,440
Ensuite, avec ce groupe de danse,
nous avons tourné dans toute l'Europe.

9
00:04:46,880 --> 00:04:51,080
Nous partions trois semaines l'été
avec 40 copains du village

10
00:04:51,840 --> 00:04:54,040
avec quelques adultes
pour s'occuper de nous.

11
00:04:54,360 --> 00:04:56,600
Nous jouions de la musique,
nous dansions.

12
00:04:57,320 --> 00:05:00,960
C'est avec cette association
que j'ai appris le répertoire

13
00:05:01,160 --> 00:05:04,520
des chants et des danses de chez nous,

14
00:05:05,360 --> 00:05:08,240
mais aussi à être sur la scène,

15
00:05:09,840 --> 00:05:11,400
à soigner le costume,

16
00:05:13,400 --> 00:05:14,400
à paraître.

17
00:05:14,920 --> 00:05:17,680
Ça a été une grande école pour moi.
`
