import React, { Component } from 'react';
import 'index.css';
import 'components/Pagination.scss';

class Pagination extends Component {
    constructor(props){
        super(props);
        this.state={
            page:1,
            reset:this.props.reset,
        }
        this.setPageClosure=this.setPageClosure.bind(this);
        this.setPage=this.setPage.bind(this);
        this.next=this.next.bind(this);
        this.prev=this.prev.bind(this);
    }
    componentDidUpdate(){
        if (this.props.reset!==this.state.reset) {
            this.setState({reset:this.props.reset},this.setPage(1));
        }
    }
    setPage(i){
        this.setState({page:i},()=>this.props.onPageChange(i));
    }
    setPageClosure(i){
        return ()=>{
            this.setPage(i);
        }
    }
    next(){
        let i=Math.min(Math.ceil(this.props.length/this.props.itemsPerPage),this.state.page+1);
        this.setPage(i);
    }
    prev(){
        let i=Math.max(1,this.state.page-1);
        this.setPage(i);
    }
    render() {
        let classes="pagination";
        let pages=[];
        let page=1;
        for (let i=0;i<this.props.length;i=i+this.props.itemsPerPage){
            pages.push(
                page===this.state.page ?
                <span key={i} className="pagination-active">{page}</span> :
                <span key={i} onClick={this.setPageClosure(page)}>{page}</span>
            )
            page++;
        }
        return <div className={classes}>
            {this.props.length >0 && <div className="catalogue-archives-pagination">
                <span className="prev-mini" onClick={this.prev}></span> {pages} <span className="next-mini" onClick={this.next}></span>
                &nbsp;&nbsp; {(this.state.page-1)*this.props.itemsPerPage+1} - {Math.min((this.state.page)*this.props.itemsPerPage,this.props.length)} / {this.props.length}
            </div>}
            {this.props.length===0 && <>Aucun live ne correspond à cette recherche.</>}
        </div>;
    }
}
export default Pagination;
