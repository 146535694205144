import React, { Component } from 'react';
import 'index.css';
import 'components/Credits.scss';
import Burger from 'components/interface/Burger';
import Data from 'assets/Data.js';
import Logo from 'assets/logo_familhas.js';

class Credits extends Component {
    constructor(props){
        super(props);
        this.state={
        }
        this.next=this.next.bind(this);
        this.prev=this.prev.bind(this);
    }
    next(){
        this.setState({idx:Math.min(Data.credits.length-1,this.state.idx+1)});
    }
    prev(){
        this.setState({idx:Math.max(0,this.state.idx-1)});
    }
    render() {
        let creditsClasses="credits";
        return <div className="credits-main">
            <div className="credits-container">
                <div className="credits-shadow">
                </div>
                <div className={creditsClasses}>
                    <div className="credits-inner">
                        <div className="credits-contour"></div>
                        <div className="credits-text">
                            <div className="logo">{Logo}</div>
                            {Data.credits}
                        </div>
                    </div>
                </div>
            </div>
            <div className="btn-box left">
            <Burger {...this.props}/>
            </div>
        </div>;
    }
}
export default Credits;
