import React, { Component } from 'react';
import 'index.css';
import 'components/MainDrawer.scss';
import Burger from 'components/interface/Burger';

class MainDrawer extends Component {
    constructor(props){
        super(props);
        this.state={
        }
        this.goTo=this.goTo.bind(this);
        this.handleBodyClick=this.handleBodyClick.bind(this);
        this.node=React.createRef();
    }
    goTo(url){
        this.props.methods.appStateSet({maindDrawerOpen:false},()=>this.props.goTo(url));
    }
    handleBodyClick(e){
        console.log(this.node.current.contains(e.target));
        if (this.props.appState.maindDrawerOpen && !this.node.current.contains(e.target)) this.props.methods.appStateSet({maindDrawerOpen:false});
    }
    componentDidMount(){
        document.body.addEventListener('click',this.handleBodyClick);
    }
    componentWillUnmount(){
        document.body.removeEventListener('click',this.handleBodyClick);
    }
    render() {
        let classes="drawer-container";
        let url=window.location.pathname.replace(this.base,'');
        if (url.indexOf('/accueil')!==-1 || url.indexOf('/credits')!==-1 || url.indexOf('/mentions')!==-1 || this.props.appState.maindDrawerOpen) classes+=" on";
        let burgerDisabled=false;
        if (this.props.appState.currentRoute && this.props.appState.currentRoute.url==="/accueil/") burgerDisabled=true;
        return <div className={classes} ref={this.node}>
            <div className="drawer">
                <div className="menu-entry menu-entry-0" onClick={()=>this.goTo('/sommaire/')}>sommaire</div>
                <div className="menu-entry menu-entry-1" onClick={()=>this.goTo('/lives/')}>lives</div>
                <div className="menu-entry menu-entry-2" onClick={()=>this.goTo('/catalogue/')}>catalogue</div>
                <div className="menu-entry-light menu-entry-light-0" onClick={()=>this.goTo('/accueil/')}>édito</div>
                <div className="menu-entry-light menu-entry-light-1" onClick={()=>this.goTo('/credits/')}>crédits</div>
                <div className="menu-entry-light menu-entry-light-2" onClick={()=>this.goTo('/mentions/')}>mentions légales</div>
            </div>
            <div className="btn-box left">
            <Burger {...this.props} disabled={burgerDisabled}/>
            </div>
        </div>;
    }
}
export default MainDrawer;
