import React, { Component } from 'react';
import 'index.css';
import 'components/Intro.scss';
import Logo from 'assets/logo_familhas.js';

class Intro extends Component {
    constructor(props){
        super(props);
        this.state={
            ready:false,
            ended:false,
        }
        this.start=this.start.bind(this);
        this.started=this.started.bind(this);
        this.ended=this.ended.bind(this);
    }
    start(){
        this.started();
        this.setState({ready:true});
    }
    started(){
        console.log('start !!');
    }
    ended(){
        console.log('end !!');
        this.setState({ended:true});
        setTimeout(()=>this.props.goTo('/pre-generique'),3000);
    }
    componentDidMount(){
        setTimeout(this.start,500);
    }
    render() {
        let AM=this.props.methods.am;
        let steps=[...Array(55).keys()];
        let classes='intro';
        if (this.state.ready) classes+=' ready';
        if (this.state.ended) classes+=' end';
        return <div className={classes}>
            {steps.map((o,i)=><img
                key={i}
                src={AM.getAssetSrc('/imgs/intro/'+((i % 11) + 1)+'.jpg')}
                className={'intro-'+i}
                onTransitionEnd={()=>i===steps.length-1 && this.ended()}
                alt=''
            />)}
            <div className="logo">{Logo}</div>
        </div>;
    }
}
export default Intro;
