/**
 * @author mrdoob / http://mrdoob.com/
 */

class EventDispatcher  {
    addEventListener ( type, listener ) {
        //console.debug('ADD EVENT',type)
        if ( this._listeners === undefined ) this._listeners = {};
        var listeners = this._listeners;
        if ( listeners[ type ] === undefined ) {
        listeners[ type ] = [];
        }
        if ( listeners[ type ].indexOf( listener ) === - 1 ) {
        listeners[ type ].push( listener );
        }
	}
	hasEventListener ( type, listener ) {
		if ( this._listeners === undefined ) return false;
		var listeners = this._listeners;
		if ( listeners[ type ] !== undefined && listeners[ type ].indexOf( listener ) !== - 1 ) {
			return true;
		}
		return false;
	}
	removeAllEventListeners( ) {
		if ( this._listeners === undefined ) return;
		for (var l in this._listeners) {
			this._listeners[l]=[];
		}
		this._listeners=null;
	}
    removeEventListener( type, listener ) {
        //console.debug('REMOVE EVENT',type)
		if ( this._listeners === undefined ) return;
		var listeners = this._listeners;
		var listenerArray = listeners[ type ];
		if ( listenerArray !== undefined ) {
			var index = listenerArray.indexOf( listener );
			if ( index !== - 1 ) {
				listenerArray.splice( index, 1 );
			}
		}
	}
    removeEventListenerType(type) {
        //console.debug('REMOVE EVENT TYPE',type)
		if ( this._listeners === undefined ) return;
		var listeners = this._listeners;
		listeners[ type ]=[];
	}
    trigger(event,data) {
        this.dispatchEvent(event,data);
    }
    once( type, listener ) {
        var that=this;
        var once=function(e){
            // remove event
            that.removeEventListener(type, once);
            // call handler
            return listener(e);
        }
        this.addEventListener( type, once);
    }
    on( type, listener ) {
        this.addEventListener( type, listener );
    }
    off( type, listener) {
        this.removeEventListener( type, listener);
    }
	dispatchEvent  ( event ,data) {
    	if (typeof event==="string") {
    		event={type:event}
    	}
		if ( this._listeners === undefined ) return;
		var listeners = this._listeners;
		var listenerArray = listeners[ event.type ];
		if ( listenerArray !== undefined ) {
			event.target = this;
			var array = [], i = 0;
			var length = listenerArray.length;
			for ( i = 0; i < length; i ++ ) {
				array[ i ] = listenerArray[ i ];
			}
			for ( i = 0; i < length; i ++ ) {
				array[ i ].call( this, event,data );
			}
		}
	}
};
export default EventDispatcher;
