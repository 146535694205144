export default `
1
00:04:41,560 --> 00:04:46,480
Quand j'étais au Québec,
j'ai fait mon chemin vers la souveraineté,

2
00:04:47,040 --> 00:04:50,920
vers le fait d'avoir une langue minorisée
ou non.

3
00:04:51,800 --> 00:04:54,800
Toute cette prise de conscience politique.

4
00:04:55,360 --> 00:04:57,440
À ce moment-là, mon frère aîné

5
00:04:58,240 --> 00:05:01,680
finissait sa formation pour être professeur d'EPS.

6
00:05:02,040 --> 00:05:06,120
Finalement, il s'est intéressé
à la musique traditionnelle.

7
00:05:06,640 --> 00:05:12,040
Lui aussi s'est enraciné politiquement
dans la défense de la langue.

8
00:05:12,520 --> 00:05:16,600
Quand je suis revenue,
j'avais envie de m'engager dans ça.

9
00:07:02,080 --> 00:07:06,400
Quand nous avons commencé Cocanha
avec Lila, nous chantions toutes les deux.

10
00:07:06,960 --> 00:07:09,160
Je voyais les Balambules et d'autres,

11
00:07:09,440 --> 00:07:14,800
je me disais : "Ces gens
ne s'aperçoivent pas qu'il y a une langue

12
00:07:15,080 --> 00:07:20,280
qui est liée à ces bals,
ces musiques traditionnelles, ces pas."

13
00:07:20,880 --> 00:07:24,360
Pour moi, la langue est présente partout.

14
00:07:25,840 --> 00:07:30,880
Pendant les bals, quand tu annonces :
"Simple tres, copar."

15
00:07:31,520 --> 00:07:32,160
C'est la langue.

16
00:07:32,560 --> 00:07:37,320
Le fait de s'exprimer
et de créer en occitan est central.

17
00:07:39,040 --> 00:07:43,440
Mais il n'y a pas une réduction esthétique.

18
00:07:44,480 --> 00:07:47,360
Ça ne conduit absolument pas
à une esthétique.

19
00:07:48,120 --> 00:07:50,440
Non, nous pouvons tout faire en occitan.

`
