export default `
1
00:08:32,800 --> 00:08:35,880
Les premiers collectages
que j'ai faits dans mon village,

2
00:08:36,160 --> 00:08:41,080
justement pour monter les projections
du Printemps de l'Arribèra,

3
00:08:41,600 --> 00:08:45,680
nous sommes allés demander aux gens
de Léren ou de Saint-Pé

4
00:08:46,360 --> 00:08:48,520
comment ils faisaient la fête,

5
00:08:49,040 --> 00:08:50,440
qu'est-ce qu'ils dansaient,

6
00:08:50,640 --> 00:08:55,400
comment ils se débrouillaient,
qui jouait de la musique,

7
00:08:56,480 --> 00:08:58,120
comment ça se passait.

8
00:08:58,960 --> 00:09:06,160
Je me suis aperçue qu'on dansait de tout,
qu'il y avait des modes.

9
00:09:07,440 --> 00:09:14,720
Je voyais que ces gens enracinés
dans la vie du village,

10
00:09:15,160 --> 00:09:16,960
qui travaillent la terre,

11
00:09:17,400 --> 00:09:21,840
qui se connaissent,
avec une vie de communauté très forte,

12
00:09:22,400 --> 00:09:29,880
eux aussi ont été influencés
par la musique américaine ou les opérettes.

13
00:09:30,800 --> 00:09:36,200
Je me suis dit : "À nous aussi de créer
un bal, des moments de fête,

14
00:09:36,600 --> 00:09:41,680
une musique, un propos artistique
qui soient connectés à tout ça."

15
00:09:43,080 --> 00:09:48,120
Je me disais : "Cette chanson me fait
penser à un univers hip-hop..."

16
00:09:50,000 --> 00:09:59,200
Sans aller jusqu'à ce type de musique,
mais d'en être consciente et d'oser.

`
