import React, { Component } from 'react';
import 'index.css';
import 'components/PreGenerique.scss';
import Logo from 'assets/logo_familhas.js';
import Conf from 'utils/Conf';

class PreGenerique extends Component {
    constructor(props){
        super(props);
        this.state={
        }
        this.ended=this.ended.bind(this);
    }
    ended(){
        this.props.goTo('/accueil');
    }
    render() {
        return <div className="pre-generique">
            <div className="cartouche-top-left slide-1">
                <div className="spacer"></div>
                <div className="spacer"></div>
                <div className="legende">réalisation</div>
                <div className="credit">Amic BEDEL</div>
                <div className="boite">Piget Films</div>
                <div className="spacer"></div>
            </div>
            <div className="cartouche-bottom-right slide-1">
                <div className="legende">développement web</div>
                <div className="credit">Fabrice Lapeyrere</div>
                <div className="boite">Le fil</div>
                <div className="spacer"></div>
            </div>
            <div className="cartouche-top-left slide-2">
                <div className="spacer"></div>
                <div className="spacer"></div>
                <div className="legende">production</div>
                <div className="credit">COMDT Toulouse Occitanie </div>
                <div className="spacer"></div>
            </div>
            <div className="cartouche-bottom-right slide-2">
                <div className="legende">en partenariat avec</div>
                <div className="credit">le CIRDÒC</div>
                <div className="soustitre">Institut occitan de cultura</div>
                <div className="spacer"></div>
            </div>
            <div className="cartouche-bottom slide-3" onAnimationEnd={this.ended}>
                <div className="legende">avec le soutien de</div>
                <div className="credit">la Région Occitanie<br/><small>Pyrénées-Méditerranée</small></div>
                <img className='logo-region' alt='' src={Conf.base+"/logos/region_occitanie.jpg"}/>
                <div className="spacer"></div>
                <div className="spacer"></div>
            </div>
            <div className="logo">{Logo}</div>
        </div>;
    }
}
export default PreGenerique;
