import React, { Component } from 'react';
import 'index.css';
import 'components/Modal.scss';
import Bouton from 'components/interface/Bouton';
import Content from 'components/Content';

class Modal extends Component {
    constructor(props){
        super(props);
        this.state={
        }
        this.content=React.createRef();
        this.handleClick=this.handleClick.bind(this);
        this.close=this.close.bind(this);
    }
    handleClick(e){
        if (e.target === e.currentTarget) this.close();
    }
    componentDidMount(){
        this.props.onOpen && this.props.onOpen();
    }
    close(){
        this.content.current.reset();
        this.props.onClose();
    }
    render() {
        let classes="modal";
        let content=this.props.content;
        if (this.props.open) classes+=" show";
        return <div className={classes} onClick={this.handleClick}>
        <Bouton text='X' className='modal-fermer' onClick={this.close}/>
        <div className="modal-container">
            <Content ref={this.content} content={content} {...this.props}/>
        </div>
        </div>;
    }
}
export default Modal;
