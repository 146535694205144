import React, { Component } from 'react';
import 'index.css';
import _ from 'lodash';
import 'components/Catalogue.scss';
import Burger from 'components/interface/Burger';
import Data from 'assets/Data.js';
import Utils from 'utils/Utils';
import Son from 'components/Son';
import Play from 'components/interface/Play';
import Pagination from 'components/Pagination';

class Catalogue extends Component {
    constructor(props){
        super(props);
        this.state={
            filter:'',
            pageArchives:1,
            pageLives:1,
            pageEntretiens:1,
            paginationReset:0,
        }
        this.handleFilterChange=this.handleFilterChange.bind(this);
        this.openModal=this.openModal.bind(this);
        this.setPageLives=this.setPageLives.bind(this);
        this.setPageArchives=this.setPageArchives.bind(this);
        this.setPageEntretiens=this.setPageEntretiens.bind(this);
    }
    openModal(c){
        this.props.methods.appStateSet({modalContent:c});
    }
    handleFilterChange(e){
        let filter=e.target.value;
        let paginationReset=this.state.paginationReset+1;
        this.setState({filter,paginationReset});
    }
    setPageArchives(i){
        this.setState({pageArchives:i});
    }
    setPageEntretiens(i){
        this.setState({pageEntretiens:i});
    }
    setPageLives(i){
        this.setState({pageLives:i});
    }
    match(a,b){
        console.log(a,b);
        let ca=Utils.accentsTidy(a.props ? a.props.children : a ).toUpperCase();
        let cb=Utils.accentsTidy(b).toUpperCase();
        return ca.indexOf(cb)!==-1;
    }
    render() {
        let AM=this.props.methods.am;
        let lives=_.sortBy(
            _.filter(Data.lives,(o)=>this.state.filter==='' || this.match(o.title,this.state.filter)),
            ['sort']
        );
        let pageLives=_.filter(lives,(o,i)=>i>=(this.state.pageLives-1)*3 && i<this.state.pageLives*3);
        let entretiens=_.sortBy(
            _.filter(Data.entretiens,(o)=>this.state.filter==='' || this.match(o.title,this.state.filter)),
            ['sort']
        );
        let pageEntretiens=_.filter(entretiens,(o,i)=>i>=(this.state.pageEntretiens-1)*3 && i<this.state.pageEntretiens*3);
        let archives=_.sortBy(
            _.filter([...Data.complements, ...Data.collectages],(o)=>this.state.filter==='' || this.match(o.title,this.state.filter)),
            ['sort']
        );
        let pageArchives=_.filter(archives,(o,i)=>i>=(this.state.pageArchives-1)*12 && i<this.state.pageArchives*12);
        return <div className="catalogue">
        <div className="catalogue-header">
            <div className="catalogue-header-title">
            CATALOGUE
            </div>
            <div className="catalogue-header-input">
                <div className="catalogue-header-input-search">
                </div>
                <input placeholder='Rechercher' onChange={this.handleFilterChange} value={this.state.filter}/>
            </div>
        </div>
        <div className="catalogue-lives">
            <div className="catalogue-lives-title">
            Lives
            </div>
            <Pagination length={lives.length} itemsPerPage={3} onPageChange={this.setPageLives} reset={this.state.paginationReset}/>
            <div className="catalogue-lives-list">
                {pageLives.map((l)=><div key={l.id} className="archive-preview-video-thumb" onClick={()=>this.props.goTo('/live/'+l.id)}>
                    <img className="archive-thumb" alt='' src={AM.getAssetSrc(l.thumbnail)}/>
                    <Play/>
                    <div className="archive-titre">{l.title}</div>
                </div>)}
            </div>
        </div>
        <div className="catalogue-entretiens">
            <div className="catalogue-entretiens-title">
            Entretiens
            </div>
            <Pagination length={entretiens.length} itemsPerPage={3} onPageChange={this.setPageEntretiens} reset={this.state.paginationReset}/>
            <div className="catalogue-lives-list">
                {pageEntretiens.map((e)=><div key={e.id} className="archive-preview-video-thumb" onClick={()=>this.openModal(e)}>
                    <img className="content-content-video-player-img" alt='' src={AM.getAssetSrc(e.image[0])}/>
                    <div className="archive-titre">{e.title}</div>
                </div>)}
            </div>
        </div>
        <div className="catalogue-archives">
            <div className="catalogue-archives-title">
                Archives
            </div>
            <Pagination length={archives.length} itemsPerPage={12} onPageChange={this.setPageArchives} reset={this.state.paginationReset}/>
            <div className="catalogue-archives-list">
                {pageArchives.map((archive)=>{
                    let style={};
                    if (archive.portrait) style={objectPosition:'0 -'+archive.dy+'px'};
                    let classe="archive-preview-video-thumb";
                    if (archive.type!=='video') classe="archive-preview-son-thumb";
                    return <div key={archive.id} className={classe} onClick={()=>this.openModal(archive)}>
                        {archive.type==='video' && <>
                            <img className="archive-thumb" alt='' src={AM.getAssetSrc(archive.thumbnail)}/>
                            <Play onClick={this.handleActivate}/>
                        </>}
                        {archive.type==='son' && <>
                            <Son
                            waveform={AM.getAssetSrc(archive.waveform[0])}
                            thumb={true}
                            objectId={archive.id+'_thumb'}
                            />
                        </>}
                        {archive.type==='sonImage' && <>
                            <img style={style} className="content-content-video-player-img" alt='' src={AM.getAssetSrc(archive.image[0])}/>
                        </>}
                        {archive.type==='image' && <>
                            <img style={style} className="content-content-video-player-img" alt='' src={AM.getAssetSrc(archive.image[0])}/>
                        </>}
                        <div className="archive-titre">{archive.title}</div>
                    </div>
                })}
            </div>
        </div>
        <div className="btn-box left">
            <Burger {...this.props}/>
            </div>
        </div>;
    }
}
export default Catalogue;
